import { AnalyticsBrowser } from '@segment/analytics-next';
import { analyticsEnabled } from '/src/utils/environment';

// eslint-disable-next-line prefer-const
let { SEGMENT_KEY, SEGMENT_CDN_URL } = process.env;

const realAnalytics = () => AnalyticsBrowser.load({ writeKey: SEGMENT_KEY, cdnURL: SEGMENT_CDN_URL });

const dummyAnalytics = () => {
  const analyticsMethods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
    'addSourceMiddleware',
    'addIntegrationMiddleware',
    'setAnonymousId',
    'addDestinationMiddleware',
  ];

  const dummyAnalyticsObject = {};
  analyticsMethods.forEach(methodName => {
    dummyAnalyticsObject[methodName] = (...args) => {
      console.log(`[analyticsEnabled: false] analytics.${methodName}() was called with:`, args);
    };
  });
  return dummyAnalyticsObject;
};

// use this first one if you need to test analytics while '?dev=true'
// export const analytics = realAnalytics();
export const analytics = analyticsEnabled ? realAnalytics() : dummyAnalytics();

export const trackExposure = (flagKey, variant) => {
  if (analyticsEnabled) {
    analytics.track('$exposure', {
      flagKey,
      variant,
    });
  }
};
