{
  "alaska": {
    "state_name": "AK",
    "site_codes": ["none"]
  },
  "alabama": {
    "state_name": "AL",
    "site_codes": ["BHM1", "DBM5", "HBM3", "HSV1", "MOB5", "WFM20", "XAL1", "XAL2"]
  },
  "arkansas": {
    "state_name": "AR",
    "site_codes": ["DLR2", "HLI1", "LIT1", "LIT2", "WSP1", "XSF2"]
  },
  "arizona": {
    "state_name": "AZ",
    "site_codes": [
      "AZA1",
      "AZA10",
      "AZA11",
      "AZA2",
      "AZA3",
      "AZA4",
      "AZA5",
      "AZA7",
      "AZA9",
      "CAZ1",
      "DAZ2",
      "DPX4",
      "DPX6",
      "DPX7",
      "DPX8",
      "DTU2",
      "DTU3",
      "DTU6",
      "DTU7",
      "DTU8",
      "DTU9",
      "GYR1",
      "GYR2",
      "GYR3",
      "GYR4",
      "HPX3",
      "HTC1",
      "JSC1",
      "JSC2",
      "KRB9",
      "PCA1",
      "PHX10",
      "PHX11",
      "PHX14",
      "PHX15",
      "PHX19",
      "PHX3",
      "PHX5",
      "PHX6",
      "PHX7",
      "PPX1",
      "SAZ1",
      "TUS1",
      "TUS2",
      "TUS5",
      "UAZ1",
      "VUWC",
      "WFM24",
      "WFM27",
      "WFM46",
      "WFM47",
      "WPR1"
    ]
  },
  "california": {
    "state_name": "CA",
    "site_codes": [
      "BFL1",
      "BFL2",
      "BUR2",
      "CNO5",
      "CSD1",
      "DAV1",
      "DAX3",
      "DAX5",
      "DAX7",
      "DAX8",
      "DCA2",
      "DCK1",
      "DCK6",
      "DCW1",
      "DCW8",
      "DCX1",
      "DCX2",
      "DCX5",
      "DCX7",
      "DCX8",
      "DDO6",
      "DFA2",
      "DFA5",
      "DFO2",
      "DFO3",
      "DFO9",
      "DFX4",
      "DFX9",
      "DIB5",
      "DIB6",
      "DJC5",
      "DJW8",
      "DLA5",
      "DLA7",
      "DLA8",
      "DLA9",
      "DLX1",
      "DLX2",
      "DLX5",
      "DLX7",
      "DLX8",
      "DLX9",
      "DOT4",
      "DPS1",
      "DPS2",
      "DPS3",
      "DPS5",
      "DPS6",
      "DSD1",
      "DSD2",
      "DSD3",
      "DSD4",
      "DSD5",
      "DSD5",
      "DSD6",
      "DSD6",
      "DSD7",
      "DSD8",
      "DSF3",
      "DSF5",
      "DSF6",
      "DSF7",
      "DSF8",
      "DSJ7",
      "DSM4",
      "DSR2",
      "DSR4",
      "DSR6",
      "DUR1",
      "DUR3",
      "DUR8",
      "DUR9",
      "DXC3",
      "DXC5",
      "DXC8",
      "FAT1",
      "FAT2",
      "FAT5",
      "HLA1",
      "HLA2",
      "HSD1",
      "HSF2",
      "HSF6",
      "HSM1",
      "ISF1",
      "ISF2",
      "ISF4",
      "ISF5",
      "JBU1",
      "JLA1",
      "JLA2",
      "JLA3",
      "JLA4",
      "JLA6",
      "JOA1",
      "JOA2",
      "JSA1",
      "JSF2",
      "JSF3",
      "JSJ1",
      "JSN3",
      "JSN4",
      "KLA1",
      "KRB4",
      "KRIV",
      "KSBD",
      "LAX10",
      "LAX16",
      "LAX18",
      "LAX21",
      "LAX22",
      "LAX25",
      "LAX26",
      "LAX32",
      "LAX39",
      "LAX5",
      "LAX61",
      "LAX62",
      "LAX71",
      "LAX9",
      "LGB10",
      "LGB2",
      "LGB3",
      "LGB4",
      "LGB5",
      "LGB6",
      "LGB7",
      "LGB8",
      "LGB9",
      "MAB1",
      "MAB4",
      "MAB5",
      "MAB8",
      "MAB9",
      "MAC0",
      "MAC2",
      "MAC3",
      "MAC4",
      "MAC9",
      "MAE6",
      "MAF3",
      "MAF4",
      "MAF9",
      "MAG1",
      "MAH8",
      "MAM7",
      "MRY11",
      "OAK3",
      "OAK4",
      "OAK5",
      "OAK7",
      "ONT1",
      "ONT2",
      "ONT5",
      "ONT6",
      "ONT8",
      "ONT9",
      "OXR1",
      "PSP1",
      "SAC5",
      "SAN13",
      "SAN15",
      "SAN3",
      "SAN5",
      "SAX2",
      "SBA11",
      "SBD1",
      "SBD2",
      "SBD3",
      "SBD5",
      "SBP1",
      "SBU2",
      "SCA1",
      "SCA4",
      "SCA5",
      "SCK1",
      "SCK3",
      "SCK4",
      "SCK6",
      "SCK9",
      "SFO11",
      "SFO12",
      "SFO13",
      "SFO20",
      "SFO21",
      "SFO22",
      "SFO24",
      "SFO28",
      "SFO36",
      "SFO38",
      "SFO46",
      "SFO53",
      "SFO60",
      "SFO8",
      "SFO9",
      "SJC1",
      "SJC10",
      "SJC11",
      "SJC13",
      "SJC14",
      "SJC18",
      "SJC25",
      "SJC26",
      "SJC29",
      "SJC3",
      "SJC31",
      "SJC32",
      "SJC7",
      "SJC9",
      "SLA2",
      "SMF1",
      "SMF3",
      "SMF5",
      "SMF6",
      "SMF7",
      "SNA11",
      "SNA12",
      "SNA3",
      "SNA4",
      "TCY5",
      "TCY9",
      "UCA1",
      "UCA2",
      "UCA4",
      "UCA5",
      "UCA6",
      "ULA6",
      "USC1",
      "USD1",
      "USF1",
      "USF2",
      "VUAC",
      "VUAI",
      "VUBD",
      "VUBH",
      "VUBP",
      "VUCH",
      "VUG9",
      "VUGE",
      "VUGK",
      "VUGR",
      "VUGT",
      "VUGY",
      "VUH1",
      "VUH7",
      "VUI1",
      "VUI5",
      "VUI6",
      "VUJ2",
      "VUJH",
      "VUJK",
      "VUJU",
      "VULL",
      "VUOI",
      "VUPC",
      "VUPD",
      "VUPG",
      "VUPL",
      "VUPQ",
      "VUPX",
      "VUTF",
      "VUTH",
      "VUVB",
      "VUWE",
      "VUWH",
      "WFM02",
      "WFM03",
      "WFM04",
      "WFM04",
      "WFM06",
      "WFM07",
      "WFM10",
      "WFM10",
      "WFM15",
      "WFM15",
      "WFM15",
      "WFM15",
      "WFM17",
      "WFM17",
      "WFM20",
      "WFM22",
      "WFM22",
      "WFM23",
      "WFM23",
      "WFM23",
      "WFM26",
      "WFM32",
      "WFM33",
      "WFM37",
      "WFM39",
      "WFM43",
      "WFM43",
      "WFM46",
      "WFM53",
      "WFM53",
      "WFM54",
      "WFM54",
      "WFM59",
      "WFM61",
      "WFM63",
      "WFM67",
      "WFM69",
      "WFM70",
      "WFM70",
      "WFM70",
      "WTH1",
      "XTE2",
      "XTE3",
      "ZFO1"
    ]
  },
  "colorado": {
    "state_name": "CO",
    "site_codes": [
      "BDU5",
      "COS5",
      "DCS3",
      "DDE6",
      "DDE8",
      "DDE9",
      "DDV2",
      "DDV3",
      "DDV4",
      "DDV5",
      "DEN17",
      "DEN2",
      "DEN22",
      "DEN29",
      "DEN3",
      "DEN4",
      "DEN5",
      "DEN6",
      "DEN7",
      "DEN8",
      "JDE1",
      "JDE2",
      "JDE3",
      "JDE4",
      "SCO1",
      "SDE1",
      "WBU12",
      "WFM21",
      "WFM27"
    ]
  },
  "connecticut": {
    "state_name": "CT",
    "site_codes": [
      "ALB1",
      "BDL2",
      "BDL3",
      "BDL4",
      "BDL5",
      "BDL6",
      "BDL7",
      "BWI1",
      "BWI11",
      "BWI4",
      "BWI6",
      "CDW5",
      "DAB8",
      "DBA5",
      "DBK1",
      "DBK6",
      "DBL1",
      "DBL8",
      "DBU3",
      "DCA50",
      "DCA51",
      "DCA54",
      "DCY1",
      "DCY9",
      "DDC2",
      "DDC3",
      "DDC4",
      "DDC9",
      "DDW1",
      "DDW7",
      "DEW2",
      "DJE1",
      "DJE2",
      "DJE3",
      "DJE5",
      "DJE6",
      "DJE9",
      "DJR1",
      "DJR5",
      "DJR6",
      "DJZ2",
      "DJZ3",
      "DJZ4",
      "DJZ5",
      "DJZ6",
      "DJZ8",
      "DLD7",
      "DMD9",
      "DNJ2",
      "DNJ3",
      "DNJ6",
      "DNJ7",
      "DNK5",
      "DNK7",
      "DNK8",
      "DNY4",
      "DOB2",
      "DOB4",
      "DVA5",
      "DXY4",
      "DYN5",
      "DYO1",
      "DYO2",
      "DYO5",
      "DYX2",
      "DYY4",
      "DYY5",
      "DYY6",
      "EWR1",
      "EWR11",
      "EWR3",
      "EWR4",
      "EWR5",
      "EWR53",
      "EWR6",
      "EWR7",
      "EWR8",
      "EWR9",
      "HBD1",
      "HEW2",
      "HFD5",
      "HNY2",
      "HNY3",
      "HNY4",
      "IAD1",
      "IAD10",
      "IAD10",
      "IAD10",
      "IAD11",
      "IAD11",
      "IAD11",
      "IAD11",
      "IAD12",
      "IAD12",
      "IAD12",
      "IAD13",
      "IAD13",
      "IAD13",
      "IAD14",
      "IAD14",
      "IAD14",
      "IAD14",
      "IAD15",
      "IAD15",
      "IAD16",
      "IAD16",
      "IAD16",
      "IAD17",
      "IAD21",
      "IAD22",
      "IAD24",
      "IAD27",
      "IAD28",
      "IAD29",
      "IAD31",
      "IAD32",
      "IAD50",
      "IAD51",
      "IAD52",
      "IAD54",
      "IAD55",
      "IAD56",
      "IAD57",
      "IAD58",
      "IAD59",
      "IAD6",
      "IAD60",
      "IAD61",
      "IAD62",
      "IAD63",
      "IAD64",
      "IAD65",
      "IAD66",
      "IAD68",
      "IAD7",
      "IAD71",
      "IAD73",
      "IAD74",
      "IAD75",
      "IAD76",
      "IAD77",
      "IAD78",
      "IAD79",
      "IAD8",
      "IAD80",
      "IAD81",
      "IAD83",
      "IAD84",
      "IAD85",
      "IAD86",
      "IAD88",
      "IAD89",
      "IAD9",
      "IAD90",
      "IAD91",
      "IAD92",
      "IAD93",
      "IAD95",
      "IAD96",
      "IAD98",
      "IAD99",
      "INY1",
      "INY4",
      "INY5",
      "INY6",
      "INY7",
      "JCT1",
      "JDC2",
      "JDC3",
      "JFK1",
      "JFK13",
      "JFK14",
      "JFK18",
      "JFK19",
      "JFK2",
      "JFK24",
      "JFK25",
      "JFK28",
      "JFK8",
      "JJF1",
      "JJF2",
      "JJF3",
      "JLG1",
      "JLG2",
      "JLG3",
      "LDJ5",
      "LGA10",
      "LGA3",
      "LGA5",
      "LGA7",
      "LGA9",
      "MAB3",
      "MAF1",
      "MAG6",
      "MAJ7",
      "MAK7",
      "PNY1",
      "SDC1",
      "SLG1",
      "SWF1",
      "TEB3",
      "TEB4",
      "TEB6",
      "TEB9",
      "TTN2",
      "UNJ1",
      "UNY1",
      "UNY2",
      "UNY5",
      "UVA1",
      "VCCNJ",
      "VCCNY",
      "WAS14",
      "WAS16",
      "WAS25",
      "WAS26",
      "WFM04",
      "WFM10",
      "WFM11",
      "WFM16",
      "WFM21",
      "WFM22",
      "WFM22",
      "WFM22",
      "WFM23",
      "WFM24",
      "WFM26",
      "WFM27",
      "WFM31",
      "WFM31",
      "WFM36",
      "WFM38",
      "WFM40",
      "WFM53",
      "WFM55",
      "WFM57",
      "WFM70",
      "ZYO1"
    ]
  },
  "dc_metro_area": {
    "state_name": "DC",
    "site_codes": [
      "BWI1",
      "BWI11",
      "BWI4",
      "BWI6",
      "DBA5",
      "DCA50",
      "DCA51",
      "DCA54",
      "DDC2",
      "DDC3",
      "DDC4",
      "DDC9",
      "DDW1",
      "DDW7",
      "DLD7",
      "DMD9",
      "DVA5",
      "IAD1",
      "IAD10",
      "IAD10",
      "IAD10",
      "IAD11",
      "IAD11",
      "IAD11",
      "IAD11",
      "IAD12",
      "IAD12",
      "IAD12",
      "IAD13",
      "IAD13",
      "IAD13",
      "IAD14",
      "IAD14",
      "IAD14",
      "IAD14",
      "IAD15",
      "IAD15",
      "IAD16",
      "IAD16",
      "IAD16",
      "IAD17",
      "IAD21",
      "IAD22",
      "IAD24",
      "IAD27",
      "IAD28",
      "IAD29",
      "IAD31",
      "IAD32",
      "IAD50",
      "IAD51",
      "IAD52",
      "IAD54",
      "IAD55",
      "IAD56",
      "IAD57",
      "IAD58",
      "IAD59",
      "IAD6",
      "IAD60",
      "IAD61",
      "IAD62",
      "IAD63",
      "IAD64",
      "IAD65",
      "IAD66",
      "IAD68",
      "IAD7",
      "IAD71",
      "IAD73",
      "IAD74",
      "IAD75",
      "IAD76",
      "IAD77",
      "IAD78",
      "IAD79",
      "IAD8",
      "IAD80",
      "IAD81",
      "IAD83",
      "IAD84",
      "IAD85",
      "IAD86",
      "IAD88",
      "IAD89",
      "IAD9",
      "IAD90",
      "IAD91",
      "IAD92",
      "IAD93",
      "IAD95",
      "IAD96",
      "IAD98",
      "IAD99",
      "JDC2",
      "JDC3",
      "MAB3",
      "MAF1",
      "MAG6",
      "MAJ7",
      "MAK7",
      "SDC1",
      "UVA1",
      "WAS14",
      "WAS16",
      "WAS25",
      "WAS26",
      "WFM04",
      "WFM11",
      "WFM21",
      "WFM22",
      "WFM22",
      "WFM53"
    ]
  },
  "delaware": {
    "state_name": "DE",
    "site_codes": ["DGI3", "HPH3", "ILG1", "MTN1", "PHL1", "PHL7", "PHL9", "VCCDE", "WDE1"]
  },
  "florida": {
    "state_name": "FL",
    "site_codes": [
      "CFL1",
      "DFH1",
      "DFL4",
      "DFL5",
      "DFL7",
      "DFL8",
      "DFM2",
      "DFM3",
      "DFM4",
      "DFM5",
      "DJX1",
      "DJX2",
      "DJX3",
      "DMF1",
      "DMF3",
      "DMF5",
      "DMF8",
      "DMI3",
      "DMI4",
      "DMI6",
      "DMI7",
      "DMI9",
      "DMO3",
      "DMO6",
      "DMO7",
      "DSR1",
      "DTP2",
      "DTP3",
      "DTP7",
      "DTP9",
      "DVB1",
      "DVB4",
      "DVB5",
      "DVB6",
      "DVB7",
      "HJX1",
      "HMI2",
      "HMO2",
      "HRS1",
      "HTP1",
      "JAX2",
      "JAX3",
      "JAX5",
      "JAX7",
      "JAX9",
      "JMC1",
      "JMI3",
      "KLAL",
      "MCO1",
      "MCO2",
      "MCO3",
      "MCO4",
      "MCO5",
      "MCO9",
      "MIA1",
      "MIA12",
      "MIA13",
      "MIA14",
      "MIA2",
      "MIA5",
      "MIA6",
      "PMI2",
      "RSW5",
      "SFL1",
      "SFL2",
      "SFL3",
      "SFL4",
      "TPA1",
      "TPA10",
      "TPA11",
      "TPA12",
      "TPA2",
      "TPA3",
      "TPA4",
      "TPA6",
      "UFL2",
      "UFL4",
      "UFL5",
      "WFM19",
      "WFM47",
      "WFM51",
      "WFM52",
      "WFM54",
      "WFM58",
      "WFM66",
      "WFM68",
      "WFM71",
      "WFM72",
      "XFL1"
    ]
  },
  "georgia": {
    "state_name": "GA",
    "site_codes": [
      "AGS1",
      "AGS2",
      "AGS5",
      "ATL11",
      "ATL12",
      "ATL2",
      "ATL5",
      "ATL55",
      "ATL6",
      "ATL7",
      "ATL8",
      "ATL9",
      "CSG1",
      "DAT1",
      "DAT2",
      "DAT6",
      "DAT9",
      "DGE4",
      "DGE7",
      "DGE9",
      "DGT2",
      "DGT8",
      "DTG5",
      "GAT1",
      "HAT1",
      "HAT2",
      "HGA6",
      "HSA1",
      "JAL1",
      "MGE1",
      "MGE3",
      "MGE5",
      "MGE8",
      "MGE9",
      "PGA1",
      "SAV3",
      "SGA1",
      "SGA2",
      "UGA2",
      "VCCGA",
      "VUAP",
      "VUCG",
      "VUGZ",
      "VUJL",
      "VUMF",
      "VUOJ",
      "VUPK",
      "VUPN",
      "VUQI",
      "VUTE",
      "WFM13",
      "WFM19",
      "WFM21",
      "WFM48",
      "WFM59",
      "WFM68"
    ]
  },
  "hawaii": {
    "state_name": "HI",
    "site_codes": ["none"]
  },
  "iowa": {
    "state_name": "IA",
    "site_codes": ["DIA3", "DIA4", "DIA5", "DSM5", "DSM9", "HDS1", "VCCIA", "WWI2"]
  },
  "idaho": {
    "state_name": "ID",
    "site_codes": ["BOI2", "BOI5", "DID2", "HBI1", "PIP14", "VCCID"]
  },
  "illinois": {
    "state_name": "IL",
    "site_codes": [
      "CHI3",
      "DCH1",
      "DCH2",
      "DCH4",
      "DCH6",
      "DCH7",
      "DCH8",
      "DCH9",
      "DEP1",
      "DIL1",
      "DIL3",
      "DIL5",
      "DIL5",
      "DIL7",
      "DLI5",
      "DLI5",
      "DLI6",
      "DLI8",
      "DLI9",
      "DLN2",
      "DLN3",
      "DLN4",
      "DLN8",
      "DMC4",
      "DXH5",
      "DXH6",
      "HCH2",
      "HCH3",
      "HIL3",
      "HMW1",
      "ICH2",
      "ICH3",
      "ICH4",
      "ICH6",
      "IGQ1",
      "IGQ2",
      "IOR1",
      "JOR1",
      "JOR2",
      "JOR3",
      "KRB6",
      "KRFD",
      "MAC1",
      "MAC7",
      "MAE3",
      "MAE4",
      "MAG3",
      "MAG4",
      "MAJ2",
      "MAK1",
      "MDW2",
      "MDW4",
      "MDW5",
      "MDW6",
      "MDW7",
      "MDW8",
      "MDW9",
      "MKE6",
      "ORD10",
      "ORD11",
      "ORD2",
      "ORD4",
      "ORD5",
      "ORD9",
      "RFD1",
      "RFD2",
      "RFD3",
      "SIL1",
      "SIL2",
      "SIL3",
      "STL4",
      "STL5",
      "STL6",
      "STL8",
      "STL9",
      "UIL1",
      "UIL2",
      "VUCA",
      "VUCL",
      "VUH8",
      "VUI2",
      "VUOK",
      "VUPI",
      "VUPS",
      "VUPV",
      "VUQO",
      "VUTG",
      "WFM11",
      "WFM17",
      "WFM17",
      "WFM25",
      "WFM27",
      "WFM29",
      "WFM45",
      "WFM48",
      "WFM56",
      "WFM56",
      "WFM57",
      "WFM57",
      "WFM64",
      "WFM66",
      "WIL1",
      "jST1"
    ]
  },
  "indiana": {
    "state_name": "IN",
    "site_codes": [
      "DII1",
      "DII3",
      "DII4",
      "DIN3",
      "DIN4",
      "DIN6",
      "DIN8",
      "FWA4",
      "HIN3",
      "HLO1",
      "IND1",
      "IND2",
      "IND3",
      "IND4",
      "IND5",
      "IND8",
      "IND9",
      "MQJ1",
      "MQJ2",
      "MQJ5",
      "MQJ9",
      "PIN2",
      "PUR1",
      "SDF11",
      "SDF8",
      "SIN9",
      "VCCIN",
      "VUBB",
      "VUH2",
      "VUQF",
      "VUQH",
      "WFM18",
      "WFM59",
      "WIN1",
      "WIN2",
      "XEV1"
    ]
  },
  "kansas": {
    "state_name": "KS",
    "site_codes": [
      "DKS3",
      "DLI3",
      "DMC3",
      "FOE1",
      "HMC2",
      "ICT2",
      "MCI5",
      "MCI7",
      "MKC3",
      "MKC4",
      "MKC6",
      "VCCKS",
      "VUI3",
      "WFM53",
      "WKS1",
      "XWI2"
    ]
  },
  "kentucky": {
    "state_name": "KY",
    "site_codes": [
      "AUV1",
      "CIN1",
      "CVG1",
      "CVG11",
      "CVG13",
      "CVG19",
      "CVG2",
      "CVG5",
      "CVG9",
      "DCM2",
      "DKY4",
      "DKY5",
      "DKY8",
      "DWO1",
      "HCN1",
      "HDY1",
      "HLO1",
      "KCVG",
      "KILN",
      "LEX1",
      "LEX11",
      "LEX2",
      "LEX5",
      "SDF1",
      "SDF11",
      "SDF8",
      "SOH2",
      "UOH5",
      "VCCKY",
      "VKY",
      "WFM18"
    ]
  },
  "louisiana": {
    "state_name": "LA",
    "site_codes": ["BTR9", "DLB2", "DNO2", "HMY1", "LFT1", "VCCLA", "WFM19", "WFM63"]
  },
  "massachusetts": {
    "state_name": "MA",
    "site_codes": [
      "BOS11",
      "BOS12",
      "BOS14",
      "BOS16",
      "BOS17",
      "BOS20",
      "BOS21",
      "BOS23",
      "BOS25",
      "BOS27",
      "BOS4",
      "BOS5",
      "BOS7",
      "BOS8",
      "DAS7",
      "DAS8",
      "DAS9",
      "DBC3",
      "DBO1",
      "DBO2",
      "DBO3",
      "DBO7",
      "DBO9",
      "DCB2",
      "DCB4",
      "DCB5",
      "DCB8",
      "DKO1",
      "DMH4",
      "DMH9",
      "DTB2",
      "DTB9",
      "HBO1",
      "JBO1",
      "JBO2",
      "JBO3",
      "JBO7",
      "OWD5",
      "OWD9",
      "SMA2",
      "UMA3",
      "VCCMA",
      "WFM02",
      "WFM09",
      "WFM09",
      "WFM15",
      "WFM31",
      "WFM38",
      "WFM45",
      "WFM50",
      "WFM51",
      "WFM61",
      "WFM62",
      "WFM69"
    ]
  },
  "maryland": {
    "state_name": "MD",
    "site_codes": [
      "BWI1",
      "BWI11",
      "BWI2",
      "BWI4",
      "BWI5",
      "BWI6",
      "DBA2",
      "DBA3",
      "DBA5",
      "DBA7",
      "DBA8",
      "DCA1",
      "DCA50",
      "DCA51",
      "DCA54",
      "DCA6",
      "DDC2",
      "DDC3",
      "DDC4",
      "DDC9",
      "DDW1",
      "DDW7",
      "DLD1",
      "DLD7",
      "DMD2",
      "DMD4",
      "DMD5",
      "DMD6",
      "DMD8",
      "DMD9",
      "DVA5",
      "HBA2",
      "HGR2",
      "HSE1",
      "IAD1",
      "IAD10",
      "IAD10",
      "IAD10",
      "IAD11",
      "IAD11",
      "IAD11",
      "IAD11",
      "IAD12",
      "IAD12",
      "IAD12",
      "IAD13",
      "IAD13",
      "IAD13",
      "IAD14",
      "IAD14",
      "IAD14",
      "IAD14",
      "IAD15",
      "IAD15",
      "IAD16",
      "IAD16",
      "IAD16",
      "IAD17",
      "IAD21",
      "IAD22",
      "IAD24",
      "IAD27",
      "IAD28",
      "IAD29",
      "IAD31",
      "IAD32",
      "IAD50",
      "IAD51",
      "IAD52",
      "IAD54",
      "IAD55",
      "IAD56",
      "IAD57",
      "IAD58",
      "IAD59",
      "IAD6",
      "IAD60",
      "IAD61",
      "IAD62",
      "IAD63",
      "IAD64",
      "IAD65",
      "IAD66",
      "IAD68",
      "IAD7",
      "IAD71",
      "IAD73",
      "IAD74",
      "IAD75",
      "IAD76",
      "IAD77",
      "IAD78",
      "IAD79",
      "IAD8",
      "IAD80",
      "IAD81",
      "IAD83",
      "IAD84",
      "IAD85",
      "IAD86",
      "IAD88",
      "IAD89",
      "IAD9",
      "IAD90",
      "IAD91",
      "IAD92",
      "IAD93",
      "IAD95",
      "IAD96",
      "IAD98",
      "IAD99",
      "JBW4",
      "JDC2",
      "JDC3",
      "KBWI",
      "KBWI",
      "LTW60",
      "LTW61",
      "LTW62",
      "MAB3",
      "MAF1",
      "MAG6",
      "MAJ7",
      "MAK7",
      "MDT2",
      "MTN2",
      "MTN5",
      "MTN6",
      "MTN9",
      "SDC1",
      "SMD1",
      "UVA1",
      "VCCMD",
      "VUCF",
      "WAS14",
      "WAS16",
      "WAS25",
      "WAS26",
      "WFM04",
      "WFM11",
      "WFM21",
      "WFM22",
      "WFM22",
      "WFM23",
      "WFM46",
      "WFM49",
      "WFM53"
    ]
  },
  "maine": {
    "state_name": "ME",
    "site_codes": ["VCCME"]
  },
  "michigan": {
    "state_name": "MI",
    "site_codes": [
      "BA1",
      "DDT1",
      "DDT2",
      "DDT3",
      "DDT4",
      "DDT6",
      "DET1",
      "DET2",
      "DET3",
      "DGR3",
      "DGR6",
      "DGR8",
      "DGX2",
      "DTW1",
      "DTW10",
      "DTW11",
      "DTW3",
      "DTW5",
      "DTW8",
      "DTW9",
      "GRR1",
      "GRR5",
      "HDT2",
      "HGR1",
      "JDT1",
      "SMI1",
      "VCCMI",
      "WFM12",
      "WFM16",
      "WFM33",
      "WFM64",
      "WMI1",
      "XSA1"
    ]
  },
  "minnesota": {
    "state_name": "MN",
    "site_codes": [
      "DMP1",
      "DMS2",
      "DMS2",
      "JMS1",
      "JMS2",
      "MSp1",
      "MSP12",
      "MSP5",
      "MSP6",
      "MSP7",
      "MSP9",
      "SMN1",
      "UMN1",
      "VCCMN",
      "WFM11",
      "WFM41",
      "WFM54",
      "WMF34",
      "WMN2"
    ]
  },
  "missouri": {
    "state_name": "MO",
    "site_codes": [
      "DKS3",
      "DLI3",
      "DLI5",
      "DLI5",
      "DLI6",
      "DLI8",
      "DLI9",
      "DMC3",
      "DMC4",
      "FOE1",
      "HMC2",
      "MCI5",
      "MCI7",
      "MKC3",
      "MKC4",
      "MKC6",
      "STL3",
      "STL4",
      "STL5",
      "STL6",
      "STL8",
      "STL9",
      "VCCKS",
      "VUH8",
      "VUI3",
      "VUPV",
      "WFM53",
      "WMO1",
      "WMO2",
      "jST1"
    ]
  },
  "mississippi": {
    "state_name": "MS",
    "site_codes": ["DTN4", "HME2", "MEM1", "MEM2", "MEM3", "MEM4", "MEM5", "MEM6", "MEM8", "VCCMS", "XMS2", "XMS4"]
  },
  "montana": {
    "state_name": "MT",
    "site_codes": ["none"]
  },
  "north_carolina": {
    "state_name": "NC",
    "site_codes": [
      "CLT11",
      "CLT12",
      "CLT2",
      "CLT3",
      "CLT4",
      "CLT5",
      "CLT6",
      "CLT9",
      "DCD6",
      "DLT1",
      "DLT2",
      "DLT3",
      "DLT6",
      "DLT7",
      "DLT8",
      "DRT1",
      "DRT4",
      "DRT7",
      "DRT8",
      "DRT9",
      "GSO1",
      "HRD2",
      "JRD1",
      "JRD2",
      "RDU1",
      "RDU2",
      "RDU5",
      "RDU9",
      "SNC2",
      "SNC3",
      "UNC2",
      "UNC3",
      "VCCNC",
      "WFM01",
      "WFM13",
      "WFM33",
      "WFM64",
      "WNC5"
    ]
  },
  "north_dakota": {
    "state_name": "ND",
    "site_codes": ["FAR1", "WND1"]
  },
  "nebraska": {
    "state_name": "NE",
    "site_codes": ["DOM2", "HOM1", "OMA2", "VCCNE", "WFM16"]
  },
  "new_hampshire": {
    "state_name": "NH",
    "site_codes": ["DBO6", "DNH2", "PMH1", "WFM18", "WFM45"]
  },
  "new_jersey": {
    "state_name": "NJ",
    "site_codes": [
      "ALB1",
      "CDW5",
      "DAB8",
      "DBK1",
      "DBK6",
      "DBL8",
      "DBU3",
      "DCY9",
      "DEW2",
      "DJE1",
      "DJE2",
      "DJE3",
      "DJE5",
      "DJE6",
      "DJE9",
      "DJR1",
      "DJR5",
      "DJR6",
      "DJZ2",
      "DJZ3",
      "DJZ4",
      "DJZ5",
      "DJZ6",
      "DJZ8",
      "DNJ2",
      "DNJ3",
      "DNJ6",
      "DNJ7",
      "DNK5",
      "DNK7",
      "DNK8",
      "DNY4",
      "DOB4",
      "DXY4",
      "DYN5",
      "DYO1",
      "DYO2",
      "DYO5",
      "DYX2",
      "DYY4",
      "DYY5",
      "DYY6",
      "EWR1",
      "EWR11",
      "EWR3",
      "EWR4",
      "EWR5",
      "EWR53",
      "EWR6",
      "EWR7",
      "EWR8",
      "EWR9",
      "HEW2",
      "HNY2",
      "HNY3",
      "HNY4",
      "INY1",
      "INY4",
      "INY5",
      "INY6",
      "INY7",
      "JCT1",
      "JFK1",
      "JFK13",
      "JFK14",
      "JFK18",
      "JFK19",
      "JFK2",
      "JFK24",
      "JFK25",
      "JFK28",
      "JFK8",
      "JJF1",
      "JJF2",
      "JJF3",
      "JLG1",
      "JLG2",
      "JLG3",
      "LDJ5",
      "LGA10",
      "LGA3",
      "LGA5",
      "LGA7",
      "LGA9",
      "PNY1",
      "SLG1",
      "SWF1",
      "TEB3",
      "TEB4",
      "TEB6",
      "TEB9",
      "TTN2",
      "UNJ1",
      "UNY1",
      "UNY2",
      "UNY5",
      "VCCNJ",
      "VCCNY",
      "WFM10",
      "WFM16",
      "WFM18",
      "WFM22",
      "WFM23",
      "WFM24",
      "WFM26",
      "WFM27",
      "WFM31",
      "WFM40",
      "WFM45",
      "WFM55",
      "WFM57",
      "WFM70",
      "ZYO1"
    ]
  },
  "new_mexico": {
    "state_name": "NM",
    "site_codes": ["ABQ1", "ABQ5", "VCCNM", "WFM14", "WQQ1", "XAQ1", "XAQ2"]
  },
  "nevada": {
    "state_name": "NV",
    "site_codes": [
      "DLV2",
      "DLV3",
      "DLV4",
      "DLV6",
      "DLV7",
      "HCA6",
      "HLV1",
      "HRN1",
      "LAS1",
      "LAS10",
      "LAS16",
      "LAS2",
      "LAS5",
      "LAS6",
      "LAS7",
      "LAS8",
      "LAS9",
      "RNO4",
      "SNV1",
      "UNV2",
      "UNV3",
      "VGT1",
      "VGT2",
      "VGT5"
    ]
  },
  "new_york": {
    "state_name": "NY",
    "site_codes": [
      "ALB1",
      "BUF5",
      "CDW5",
      "DAB4",
      "DAB8",
      "DBK1",
      "DBK6",
      "DBL8",
      "DBU1",
      "DBU2",
      "DBU3",
      "DCY9",
      "DEW2",
      "DJE1",
      "DJE2",
      "DJE3",
      "DJE5",
      "DJE6",
      "DJE9",
      "DJR1",
      "DJR5",
      "DJR6",
      "DJZ2",
      "DJZ3",
      "DJZ4",
      "DJZ5",
      "DJZ6",
      "DJZ8",
      "DNJ2",
      "DNJ3",
      "DNJ6",
      "DNJ7",
      "DNK5",
      "DNK7",
      "DNK8",
      "DNY4",
      "DOB4",
      "DRO1",
      "DRO2",
      "DXY4",
      "DYN5",
      "DYO1",
      "DYO2",
      "DYO5",
      "DYX2",
      "DYY4",
      "DYY5",
      "DYY6",
      "EWR1",
      "EWR11",
      "EWR3",
      "EWR4",
      "EWR5",
      "EWR53",
      "EWR6",
      "EWR7",
      "EWR8",
      "EWR9",
      "HEW2",
      "HNY2",
      "HNY3",
      "HNY4",
      "HRO1",
      "HSY1",
      "INY1",
      "INY4",
      "INY5",
      "INY6",
      "INY7",
      "JCT1",
      "JFK1",
      "JFK13",
      "JFK14",
      "JFK18",
      "JFK19",
      "JFK2",
      "JFK24",
      "JFK25",
      "JFK28",
      "JFK8",
      "JGF1",
      "JJF1",
      "JJF2",
      "JJF3",
      "JLG1",
      "JLG2",
      "JLG3",
      "LDJ5",
      "LGA10",
      "LGA3",
      "LGA5",
      "LGA7",
      "LGA9",
      "PNY1",
      "SLG1",
      "SWF1",
      "SYR1",
      "TEB3",
      "TEB4",
      "TEB6",
      "TEB9",
      "TTN2",
      "UNJ1",
      "UNY1",
      "UNY2",
      "UNY5",
      "VCCNJ",
      "VCCNY",
      "WFM10",
      "WFM16",
      "WFM22",
      "WFM23",
      "WFM24",
      "WFM26",
      "WFM27",
      "WFM31",
      "WFM40",
      "WFM55",
      "WFM57",
      "WFM60",
      "WFM70",
      "WNY1",
      "ZYO1"
    ]
  },
  "ohio": {
    "state_name": "OH",
    "site_codes": [
      "AKC1",
      "AUV1",
      "CIN1",
      "CLE1",
      "CLE10",
      "CLE2",
      "CLE3",
      "CLE5",
      "CMH1",
      "CMH2",
      "CMH3",
      "CMH4",
      "CMH5",
      "CMH50",
      "CMH51",
      "CMH52",
      "CMH53",
      "CMH54",
      "CMH55",
      "CMH56",
      "CMH57",
      "CMH58",
      "CMH59",
      "CMH6",
      "CMH60",
      "CMH61",
      "CMH62",
      "CVG1",
      "CVG11",
      "CVG13",
      "CVG19",
      "CVG2",
      "CVG5",
      "CVG9",
      "DCL1",
      "DCL2",
      "DCL3",
      "DCL4",
      "DCL7",
      "DCM2",
      "DCM3",
      "DCM5",
      "DCM6",
      "DCM7",
      "DCN2",
      "DKY4",
      "DKY8",
      "DLC5",
      "DWO1",
      "HCE2",
      "HCN1",
      "HDY1",
      "KCVG",
      "KILN",
      "LCK50",
      "LCK51",
      "LCK52",
      "LCK54",
      "LEX5",
      "OSU1",
      "PCW1",
      "SOH1",
      "SOH2",
      "SOH3",
      "UOH4",
      "UOH5",
      "VUPR",
      "WFM48",
      "WFM55",
      "WOH1"
    ]
  },
  "oklahoma": {
    "state_name": "OK",
    "site_codes": [
      "DOK1",
      "DOK2",
      "DOK3",
      "DOK4",
      "HOK2",
      "JOK1",
      "OKC1",
      "OKC2",
      "OKC5",
      "TUL2",
      "TUL5",
      "VCCOK",
      "WFM37",
      "WFM47",
      "XJO1",
      "XWI1",
      "XWI4",
      "XWI5"
    ]
  },
  "oregon": {
    "state_name": "OR",
    "site_codes": [
      "ACY1",
      "ACY2",
      "ACY5",
      "ACY8",
      "ACY9",
      "DAE8",
      "DDP1",
      "DDP2",
      "DDP5",
      "DDP7",
      "DDP9",
      "DEW4",
      "DEW5",
      "DEW8",
      "DEY6",
      "DNK2",
      "DPD2",
      "DPD4",
      "DPD5",
      "DPD6",
      "DPD7",
      "DPH1",
      "DPH6",
      "DPH8",
      "DPH9",
      "DPL4",
      "EUG5",
      "HEU1",
      "HPH1",
      "JPD1",
      "JPH3",
      "LCK60",
      "MAF7",
      "MCO6",
      "PDT10",
      "PDX1",
      "PDX10",
      "PDX11",
      "PDX13",
      "PDX13",
      "PDX17",
      "PDX2",
      "PDX4",
      "PDX5",
      "PDX50",
      "PDX51",
      "PDX52",
      "PDX53",
      "PDX54",
      "PDX55",
      "PDX56",
      "PDX57",
      "PDX58",
      "PDX59",
      "PDX6",
      "PDX60",
      "PDX61",
      "PDX62",
      "PDX63",
      "PDX64",
      "PDX65",
      "PDX66",
      "PDX66",
      "PDX67",
      "PDX67",
      "PDX68",
      "PDX68",
      "PDX69",
      "PDX69",
      "PDX7",
      "PDX70",
      "PDX71",
      "PDX72",
      "PDX73",
      "PDX74",
      "PDX75",
      "PDX76",
      "PDX77",
      "PDX78",
      "PDX79",
      "PDX79",
      "PDX80",
      "PDX81",
      "PDX9",
      "PDX90",
      "PDX91",
      "PDX92",
      "PEN1",
      "PHL10",
      "PNE5",
      "PPH3",
      "UNJ2",
      "UOR2",
      "UPA1",
      "VCCPA",
      "WFM29",
      "WFM35",
      "WFM52"
    ]
  },
  "pennsylvania": {
    "state_name": "PA",
    "site_codes": [
      "ABE2",
      "ABE2",
      "ABE3",
      "ABE3",
      "ABE4",
      "ABE4",
      "ABE5",
      "ABE5",
      "ABE8",
      "ABE8",
      "ACY1",
      "ACY2",
      "ACY5",
      "ACY8",
      "ACY9",
      "AVP1",
      "AVP1",
      "AVP3",
      "AVP3",
      "AVP8",
      "AVP8",
      "AVP9",
      "AVP9",
      "DAE1",
      "DAE1",
      "DAE3",
      "DAE3",
      "DAE8",
      "DDP1",
      "DDP2",
      "DDP5",
      "DDP7",
      "DDP9",
      "DEW4",
      "DEW5",
      "DEW8",
      "DEY6",
      "DNK2",
      "DPH1",
      "DPH6",
      "DPH7",
      "DPH7",
      "DPH8",
      "DPH9",
      "DPL4",
      "DPL7",
      "DPL7",
      "DPP1",
      "DPP7",
      "HPH1",
      "HPH2",
      "HPH2",
      "HPT1",
      "HVP1",
      "HVP1",
      "JPH3",
      "MAF7",
      "MCO6",
      "MDT1",
      "MDT1",
      "MDT5",
      "MDT5",
      "PEN1",
      "PHL10",
      "PHL4",
      "PHL4",
      "PHL5",
      "PHL5",
      "PHL6",
      "PHL6",
      "PIT10",
      "PIT2",
      "PIT5",
      "PIT9",
      "PNE5",
      "PPH3",
      "RDG1",
      "RDG1",
      "SCE1",
      "SCE1",
      "TEN1",
      "TEN1",
      "UNJ2",
      "UPA1",
      "VCCPA",
      "WFM18",
      "WFM35",
      "WFM52"
    ]
  },
  "rhode_island": {
    "state_name": "RI",
    "site_codes": ["DRI1", "WFM11", "WFM25"]
  },
  "south_carolina": {
    "state_name": "SC",
    "site_codes": ["CAE1", "CAE3", "CHS10", "DSC2", "DSC3", "DSC4", "GSP1", "VCCSC", "WFM20", "WFM22"]
  },
  "south_dakota": {
    "state_name": "SD",
    "site_codes": ["FSD1", "VCCCSD", "WSD1"]
  },
  "tennessee": {
    "state_name": "TN",
    "site_codes": [
      "BNA10",
      "BNA12",
      "BNA13",
      "BNA14",
      "BNA15",
      "BNA16",
      "BNA2",
      "BNA3",
      "BNA5",
      "BNA7",
      "BNA8",
      "BNA9",
      "CHA1",
      "CHA2",
      "DNA1",
      "DTN6",
      "DTN7",
      "DTN8",
      "HBN2",
      "HCT2",
      "HKX1",
      "JBN1",
      "MQY1",
      "RAD1",
      "STN1",
      "UTN1",
      "VCCTN",
      "WFM24",
      "WFM32",
      "DTN4",
      "HME2",
      "MEM1",
      "MEM2",
      "MEM3",
      "MEM4",
      "MEM5",
      "MEM6",
      "MEM8"
    ]
  },
  "texas": {
    "state_name": "TX",
    "site_codes": [
      "AFW1",
      "AFW5",
      "ALE62",
      "AMA1",
      "AUS10",
      "AUS11",
      "AUS13",
      "AUS16",
      "AUS17",
      "AUS19",
      "AUS2",
      "AUS3",
      "AUS5",
      "DAL2",
      "DAL3",
      "DAL9",
      "DAU1",
      "DAU2",
      "DAU5",
      "DAU7",
      "DDA1",
      "DDA2",
      "DDA3",
      "DDA8",
      "DDA9",
      "DDF1",
      "DDF2",
      "DDF4",
      "DDF5",
      "DDF6",
      "DDF8",
      "DDX2",
      "DDX6",
      "DDX7",
      "DFW11",
      "DFW15",
      "DFW5",
      "DFW6",
      "DFW7",
      "DFW8",
      "DFW9",
      "DHO2",
      "DHO3",
      "DHO4",
      "DHO5",
      "DHO7",
      "DHO8",
      "DHT1",
      "DHX1",
      "DHX3",
      "DHX4",
      "DHX5",
      "DPA7",
      "DSX1",
      "DSX3",
      "DSX5",
      "DSX7",
      "DSX9",
      "ELP1",
      "FTW1",
      "FTW2",
      "FTW3",
      "FTW4",
      "FTW5",
      "FTW6",
      "FTW7",
      "FTW8",
      "FTW9",
      "HAU1",
      "HHO2",
      "HOU1",
      "HOU11",
      "HOU14",
      "HOU2",
      "HOU3",
      "HOU5",
      "HOU6",
      "HOU7",
      "HOU8",
      "HOU9",
      "IAH1",
      "IAH5",
      "JAU1",
      "JDA1",
      "JIA1",
      "JIA2",
      "KAFW",
      "LBB1",
      "LBB5",
      "PAU2",
      "RBD5",
      "SAT1",
      "SAT2",
      "SAT4",
      "SAT5",
      "SAT6",
      "SAT9",
      "SIA1",
      "STX2",
      "STX3",
      "STX4",
      "STX5",
      "STX6",
      "STX7",
      "UTX3",
      "UTX4",
      "UTX8",
      "UTX9",
      "WTX1",
      "WTX2",
      "WTX3",
      "WTX4",
      "WTX5"
    ]
  },
  "utah": {
    "state_name": "UT",
    "site_codes": [
      "DUT2",
      "DUT3",
      "DUT4",
      "DUT7",
      "PIP13",
      "SLC1",
      "SLC10",
      "SLC16",
      "SLC2",
      "SLC3",
      "SLC4",
      "SLC9",
      "SUT1",
      "VCCUT",
      "WFM28"
    ]
  },
  "virginia": {
    "state_name": "VA",
    "site_codes": [
      "BWI1",
      "BWI11",
      "BWI4",
      "BWI6",
      "DBA5",
      "DCA50",
      "DCA51",
      "DCA54",
      "DDC2",
      "DDC3",
      "DDC4",
      "DDC9",
      "DDW1",
      "DDW7",
      "DLD7",
      "DMD9",
      "DOR1",
      "DOR2",
      "DOR3",
      "DOR8",
      "DRC1",
      "DVA2",
      "DVA3",
      "DVA5",
      "HVB1",
      "HVB2",
      "IAD1",
      "IAD10",
      "IAD10",
      "IAD10",
      "IAD11",
      "IAD11",
      "IAD11",
      "IAD11",
      "IAD12",
      "IAD12",
      "IAD12",
      "IAD13",
      "IAD13",
      "IAD13",
      "IAD14",
      "IAD14",
      "IAD14",
      "IAD14",
      "IAD15",
      "IAD15",
      "IAD16",
      "IAD16",
      "IAD16",
      "IAD17",
      "IAD21",
      "IAD22",
      "IAD24",
      "IAD27",
      "IAD28",
      "IAD29",
      "IAD31",
      "IAD32",
      "IAD50",
      "IAD51",
      "IAD52",
      "IAD54",
      "IAD55",
      "IAD56",
      "IAD57",
      "IAD58",
      "IAD59",
      "IAD6",
      "IAD60",
      "IAD61",
      "IAD62",
      "IAD63",
      "IAD64",
      "IAD65",
      "IAD66",
      "IAD68",
      "IAD7",
      "IAD71",
      "IAD73",
      "IAD74",
      "IAD75",
      "IAD76",
      "IAD77",
      "IAD78",
      "IAD79",
      "IAD8",
      "IAD80",
      "IAD81",
      "IAD83",
      "IAD84",
      "IAD85",
      "IAD86",
      "IAD88",
      "IAD89",
      "IAD9",
      "IAD90",
      "IAD91",
      "IAD92",
      "IAD93",
      "IAD95",
      "IAD96",
      "IAD98",
      "IAD99",
      "JDC2",
      "JDC3",
      "KRB2",
      "MAB3",
      "MAF1",
      "MAG6",
      "MAJ7",
      "MAK7",
      "ORF2",
      "ORF3",
      "RIC1",
      "RIC2",
      "RIC3",
      "RIC5",
      "SDC1",
      "UVA1",
      "UVA4",
      "UVA5",
      "WAS14",
      "WAS16",
      "WAS25",
      "WAS26",
      "WCH2",
      "WFM04",
      "WFM11",
      "WFM21",
      "WFM22",
      "WFM22",
      "WFM46",
      "WFM53",
      "WTN1",
      "XCV3",
      "XCV5",
      "XCV7",
      "XCV8"
    ]
  },
  "vermont": {
    "state_name": "VT",
    "site_codes": ["none"]
  },
  "washington": {
    "state_name": "WA",
    "site_codes": [
      "BFI1",
      "BFI3",
      "BFI4",
      "BFI5",
      "BFI6",
      "BFI7",
      "BFI8",
      "BFI9",
      "BFIX",
      "DSE5",
      "DSE8",
      "DSW5",
      "DSW5",
      "DWA2",
      "DWA5",
      "DWA6",
      "DWA7",
      "DWA9",
      "DWS4",
      "DWS5",
      "FSE2",
      "FSE3",
      "GEG1",
      "GEG2",
      "HBF2",
      "HBF4",
      "HBF5",
      "HGE1",
      "IBF1",
      "IBF3",
      "IBF4",
      "ILS4",
      "ISE1",
      "ISE6",
      "ISE7",
      "ISE9",
      "JSE1",
      "JSE2",
      "JSE3",
      "JSE4",
      "JSE5",
      "JSE7",
      "LPK1",
      "MAB2",
      "MAB6",
      "MAB7",
      "OLM1",
      "PSC1",
      "PSE1",
      "RNT9",
      "SEA10",
      "SEA10",
      "SEA11",
      "SEA11",
      "SEA12",
      "SEA12",
      "SEA18",
      "SEA20",
      "SEA22",
      "SEA23",
      "SEA25",
      "SEA27",
      "SEA28",
      "SEA29",
      "SEA3",
      "SEA32",
      "SEA33",
      "SEA35",
      "SEA36",
      "SEA39",
      "SEA40",
      "SEA41",
      "SEA42",
      "SEA43",
      "SEA44",
      "SEA45",
      "SEA47",
      "SEA48",
      "SEA51",
      "SEA53",
      "SEA54",
      "SEA55",
      "SEA57",
      "SEA58",
      "SEA59",
      "SEA68",
      "SEA69",
      "SEA70",
      "SEA71",
      "SEA74",
      "SEA75",
      "SEA76",
      "SEA77",
      "SEA81",
      "SEA82",
      "SEA83",
      "SEA84",
      "SEA85",
      "SEA86",
      "SEA88",
      "SEA89",
      "SEA90",
      "SEA91",
      "SEA93",
      "SEA94",
      "SEA97",
      "SSE1",
      "SWA1",
      "SWA2",
      "TRB",
      "UWA1",
      "UWA2",
      "UWA5",
      "VCCWA",
      "VUPM",
      "WFM10",
      "WFM15",
      "WFM27",
      "WFM43",
      "WFM58",
      "WFM63",
      "ZSE1"
    ]
  },
  "wisconsin": {
    "state_name": "WI",
    "site_codes": [
      "DML2",
      "DML3",
      "DML4",
      "DML5",
      "DML8",
      "DWI4",
      "HMK2",
      "HMK3",
      "HMK4",
      "JVL1",
      "MKE1",
      "MKE2",
      "MKE5",
      "MSN4",
      "MSN5",
      "SWI1",
      "UWI1",
      "UWI2",
      "VUJE",
      "VUQJ",
      "VURY",
      "WFM04",
      "WFM58",
      "WIS1",
      "WWI3",
      "WWI4"
    ]
  },
  "west_virginia": {
    "state_name": "WV",
    "site_codes": ["HTS3", "VCCWV"]
  },
  "wyoming": {
    "state_name": "WY",
    "site_codes": ["none"]
  }
}
