export const submitHubspotForm = (formId, data) => {
  // Create the new request
  const xhr = new XMLHttpRequest();
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/22286298/${formId}`;
  const finalData = JSON.stringify(data);

  xhr.open('POST', url);
  // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
  xhr.setRequestHeader('Content-Type', 'application/json');

  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4 && xhr.status === 200) {
      return xhr.responseText; // Returns a 200 response if the submission is successful.
    }
    if (xhr.readyState === 4 && xhr.status === 400) {
      return xhr.responseText; // Returns a 400 error the submission is rejected.
    }
    if (xhr.readyState === 4 && xhr.status === 403) {
      return xhr.responseText; // Returns a 403 error if the portal isn't allowed to post submissions.
    }
    if (xhr.readyState === 4 && xhr.status === 404) {
      return xhr.responseText; // Returns a 404 error if the formGuid isn't found
    }
    return 'Done';
  };

  // Sends the request
  xhr.send(finalData);
};
