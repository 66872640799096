import { useEffect, useState } from 'react';
import { getEmployerRecord } from './api/airtableConfigs';
import * as styles from './EligibilityResults.module.scss';
import { IneligibleForm } from './Forms/IneligibleForm';

export const EligibilityResults = () => {
  const url = new URL(window.location.href);
  const employerId = url.searchParams.get('utm_content');

  const [employerName, setEmployerName] = useState('');
  const [employerLogo, setEmployerLogo] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const employerRecord = await getEmployerRecord(employerId);
      setEmployerName(employerRecord[0]['Employer Name']);
      if (employerRecord[0].Logo) setEmployerLogo(employerRecord[0].Logo[0].url);
      else setEmployerLogo('');
      setConfirmationMessage(employerRecord[0]['Confirmation Message']);
    };
    fetchData();
  }, [employerId]);

  return employerId && employerId !== 'sorry' ? (
    <div className={styles.eligibilityResults}>
      <div className={styles.eligibilityResultsInner}>
        <div className={styles.eligibilityResultsLogo}>
          {employerLogo && <img src={employerLogo} alt={employerName} />}
        </div>
        <div className={styles.eligibilityResultsConfirmation}>
          <h2>{confirmationMessage}</h2>
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.eligibilityResults}>
      <div className={styles.eligibilityResultsInner}>
        <div className={styles.eligibilityResultsConfirmation}>
          <div className={styles['ineligible-text']}>
            <h1 className={styles['ineligible-text']}>Sorry, that employer isn&apos;t in our network yet.</h1>
            <h3 className={styles['ineligible-text']}>
              We&apos;re working hard to expand our network of employers. Sign up to be notified when your employer is
              added to our network.
            </h3>
          </div>
          <IneligibleForm />
        </div>
      </div>
    </div>
  );
};
