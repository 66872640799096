{
  "siteCodes": [
    "ABE2",
    "ABE3",
    "ABE4",
    "ABE8",
    "ABQ1",
    "ACY1",
    "ACY2",
    "AFW1",
    "AGS1",
    "AGS2",
    "AKC1",
    "AKR1",
    "ALB1",
    "AMA1",
    "ATL2",
    "ATL7",
    "AUS2",
    "AUS3",
    "AVP1",
    "AVP8",
    "BDL2",
    "BDL3",
    "BDL4",
    "BFI3",
    "BFI4",
    "BFI9",
    "BFL1",
    "BFL2",
    "BHM1",
    "BNA2",
    "BNA3",
    "BOI2",
    "BOS3",
    "BOS7",
    "BWI1",
    "BWI2",
    "BWI4",
    "CAE1",
    "CHA1",
    "CHA2",
    "CHO1",
    "CLE2",
    "CLE3",
    "CLT2",
    "CLT3",
    "CLT4",
    "CMH1",
    "CMH2",
    "CMH3",
    "CMH4",
    "CMH7",
    "CSG1",
    "CVG2",
    "DAL2",
    "DAL3",
    "DCA1",
    "DCA6",
    "DEN2",
    "DEN3",
    "DEN4",
    "DEN8",
    "DET1",
    "DET2",
    "DET3",
    "DET6",
    "DFW6",
    "DFW7",
    "DSM5",
    "DTW1",
    "ELP1",
    "EWR4",
    "EWR7",
    "EWR9",
    "FAR1",
    "FAT1",
    "FAT2",
    "FOE1",
    "FSD1",
    "FTW1",
    "FTW3",
    "FTW5",
    "FTW6",
    "FTW8",
    "FTW9",
    "FWA4",
    "FWA6",
    "GEG1",
    "GEG2",
    "GRR1",
    "GSO1",
    "GSP1",
    "GYR1",
    "GYR2",
    "GYR3",
    "HOU2",
    "HOU3",
    "HOU6",
    "HOU8",
    "HSV1",
    "IAH1",
    "IAH3",
    "ICT2",
    "IGQ1",
    "IGQ2",
    "ILG1",
    "IND1",
    "IND2",
    "IND4",
    "IND5",
    "IND8",
    "IND9",
    "JAX2",
    "JAX3",
    "JAX7",
    "JFK8",
    "JVL1",
    "KRB2",
    "KRB4",
    "KRB6",
    "KRB9",
    "LAS1",
    "LAS2",
    "LAS6",
    "LAS7",
    "LAX9",
    "LEX1",
    "LEX2",
    "LFT1",
    "LGA9",
    "LGB3",
    "LGB4",
    "LGB6",
    "LGB7",
    "LGB8",
    "LIT1",
    "LIT2",
    "LUK2",
    "LUK7",
    "MCE1",
    "MCI7",
    "MCO1",
    "MCO2",
    "MDT1",
    "MDT2",
    "MDT4",
    "MDW2",
    "MDW4",
    "MDW6",
    "MDW7",
    "MDW9",
    "MEM1",
    "MEM2",
    "MEM3",
    "MEM4",
    "MEM6",
    "MGE1",
    "MGE3",
    "MIA1",
    "MKC4",
    "MKC6",
    "MKE1",
    "MKE2",
    "MLI1",
    "MQJ1",
    "MQY1",
    "MSP1",
    "MTN1",
    "OAK3",
    "OAK4",
    "OKC1",
    "OKC2",
    "OLM1",
    "OMA2",
    "ONT2",
    "ONT6",
    "ONT8",
    "ONT9",
    "ORD2",
    "ORD5",
    "ORF2",
    "ORF3",
    "OXR1",
    "PAE2",
    "PCA1",
    "PCW1",
    "PDX7",
    "PDX9",
    "PGA1",
    "PHL4",
    "PHL5",
    "PHL6",
    "PHL7",
    "PHX3",
    "PHX5",
    "PHX6",
    "PHX7",
    "PIT2",
    "PSP1",
    "RDG1",
    "RDU1",
    "RIC1",
    "RIC2",
    "RIC4",
    "RNO4",
    "ROC1",
    "SAN3",
    "SAT1",
    "SAT2",
    "SAT3",
    "SAT4",
    "SAV3",
    "SAV4",
    "SAV7",
    "SBD1",
    "SBD2",
    "SBD3",
    "SCK1",
    "SCK4",
    "SCK6",
    "SCK8",
    "SDF1",
    "SDF4",
    "SDF6",
    "SDF8",
    "SDF9",
    "SJC7",
    "SLC1",
    "SLC2",
    "SMF1",
    "SMF3",
    "SMF6",
    "SNA4",
    "STL3",
    "STL4",
    "STL8",
    "SWF1",
    "SWF2",
    "SYR1",
    "TEB3",
    "TEB4",
    "TEB6",
    "TEB9",
    "TEN1",
    "TLH2",
    "TPA1",
    "TPA2",
    "TPA3",
    "TPA4",
    "TUL2",
    "TUS1",
    "TUS2",
    "TYS1",
    "VGT1",
    "VGT2"
  ]
}
