.welcomeToPathstream {
  max-width: 1000px;
  margin: auto;
  color: #000;
  padding-bottom: 100px;

  .title {
    text-align: center;
    margin-bottom: 20px;

    .main {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .subtitle {
      font-size: 26px;
      line-height: 1.3em;
    }
  }

  .info {
    display: flex;

    .program {
      max-width: 400px;
      padding: 0 40px;
      font-weight: bold;

      .yourProgram {
        text-transform: uppercase;
        font-size: 11px;
        color: #bababa;
        letter-spacing: 2px;
      }

      .programName {
        font-size: 28px;
        line-height: 1.3em;
      }

      .partnerName {
        font-size: 12px;
        margin-bottom: 10px;
      }

      .courseDates {
        font-size: 18px;
        margin-bottom: 15px;
      }

      .syllabusButton {
        color: #1e557a;
        background: #c9edfb;
        padding: 10px 20px;
        border-radius: 5px;
        font-weight: bold;
        text-align: center;
        display: inline-block;
        margin-bottom: 10px;

        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }
    }

    .courses {
      font-weight: 700;
      background-color: #ebf8ff;
      border-radius: 5px;
      padding: 20px 50px;
      max-width: 700px;
      min-width: 700px;

      .coursesIntro {
        text-transform: uppercase;
        font-size: 10px;
        color: #1e557a;
        letter-spacing: 1px;
      }

      .courseContainer {
        margin-bottom: 20px;

        .courseDates {
          font-size: 14px;
          margin-bottom: 2px;
        }

        .courseTitle {
          font-size: 20px;
          font-weight: bold;

          .courseNumber {
            font-weight: normal;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1000px) {
    .title {
      width: 100%;
      padding: 0 20px;

      .subtitle {
        font-size: 18px;
        line-height: 1.3em;
      }
    }

    .info {
      flex-wrap: wrap;

      .program {
        width: 100%;
        margin-bottom: 50px;
      }

      .courses {
        width: 100%;
      }
    }
  }
}
