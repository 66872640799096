/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
// import * as EmailValidator from 'email-validator';
import * as styles from '../Apply1Form.module.scss';
import { ErrorMessage } from '../ErrorMessage';

const TextField = props => (
  <div className={props.fieldClass}>
    <label htmlFor={props.fieldName} className={styles['form-label']}>
      {props.labelText}
    </label>
    <div className={styles['field-wrapper']}>
      <input
        type={props.fieldType}
        id={props.fieldName}
        onBlur={props.onBlur}
        placeholder={props.placeholder}
        onChange={props.onChange}
        name={props.fieldName}
        value={props.textValue}
      />
      {props.error && ErrorMessage(props.error)}
    </div>
  </div>
);

TextField.propTypes = {
  fieldType: PropTypes.string.isRequired,
  fieldClass: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  textValue: PropTypes.string.isRequired,
  error: PropTypes.string,
};

TextField.defaultProps = {
  error: '',
};

export default TextField;
