import { environment } from './environment';

window.DD_RUM = {
  q: [],
  onReady: callback => {
    window.DD_RUM.q.push(callback);
  },
};

window.DD_RUM.onReady(() => {
  window.DD_RUM.init({
    clientToken: 'pub8f03b2a2a865f959fb1e90d1f5ec2d3c',
    applicationId: 'c59b5bec-9750-49b5-a57c-e4b933e6a1dd',
    site: 'datadoghq.com',
    service: 'blog-js',
    env: environment,
    version: process.env.GITHUB_SHA,
    trackInteractions: true,
    allowedTracingOrigins: [/https:\/\/.*\.pathstream\.com/],
  });
});

export const loadDatadog = () => {
  const head = document.getElementsByTagName('HEAD')[0];
  const script = document.createElement('script');
  script.async = 1;
  script.src = 'https://www.datadoghq-browser-agent.com/datadog-rum-v4.js';
  head.appendChild(script);
};

export const setUser = (id, name, email) => {
  window.DD_RUM.onReady(() => {
    window.DD_RUM.setUser({ id, name, email });
  });
};

export const addError = (message, { error, context }) => {
  const datadogError = new Error(message, { cause: error });
  console.error(datadogError);
  console.error(error);
  window.DD_RUM.onReady(() => {
    window.DD_RUM.addError(datadogError, context);
  });
};
