import React from 'react';
import PropTypes from 'prop-types';
// import * as EmailValidator from 'email-validator';
import * as styles from '../Apply1Form.module.scss';

const MCQuestion = ({ questionText, questionNumber, answerOptions, currentAnswers, updateAnswers }) => {
  const handleChange = e => {
    const answer = e.target.value;
    const newAnswers = [...currentAnswers];
    newAnswers[questionNumber - 1] = answer;
    updateAnswers(newAnswers);
  };

  const letters = ['A', 'B', 'C', 'D'];

  return (
    <div className={styles.mcQuestion}>
      <p>{questionText}</p>
      {answerOptions.map((option, index) => (
        <div>
          <input
            type="radio"
            id={`option-${questionNumber}-${letters[index]}`}
            name={`question-${questionNumber}`}
            value={letters[index]}
            onChange={handleChange}
            checked={currentAnswers[questionNumber - 1].includes(letters[index])}
          />
          <label htmlFor={`option-${questionNumber}-${letters[index]}`}>
            <strong>{`${letters[index]}:`}</strong> {`${option}`}
          </label>
        </div>
      ))}
    </div>
  );
};

MCQuestion.propTypes = {
  questionText: PropTypes.string.isRequired,
  answerOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  questionNumber: PropTypes.number.isRequired,
  currentAnswers: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateAnswers: PropTypes.func.isRequired,
};

export default MCQuestion;
