import { useEffect, useState } from 'react';
import Select from 'react-select';
import { getEligibleEmployers } from './api/airtableConfigs';
import { customEventInelegibleSearch } from './analytics/customEvents';

export const EligibilitySearch = () => {
  const [search, setSearch] = useState('');
  // const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [optionsShow, setOptionsShow] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const eligibleEmployers = await getEligibleEmployers();
      setSuggestions(eligibleEmployers);
      // console.log(eligibleEmployers[0]['Employer Name']);
    };
    fetchData();
    const url = new URL(window.location.href);
    if (url.searchParams.get('employer')) {
      customEventInelegibleSearch({ employer_search: url.searchParams.get('employer') });
    }
  }, []);

  const performSearch = searchTerm => {
    // console.log('searching for', searchTerm);
    const url = new URL(window.location.href);
    let employerSearch = 'sorry';
    let partnerParam = 'none';
    //  console.log(suggestions);
    suggestions.forEach(suggestion => {
      // console.log(suggestion);
      if (
        suggestion.Eligible === true &&
        ((suggestion['Employer Name'] && suggestion['Employer Name'].toLowerCase() === searchTerm.toLowerCase()) ||
          suggestion['employer ID'] === searchTerm)
      ) {
        employerSearch = suggestion['employer ID'];
        partnerParam = suggestion.Partner;
      }
    });
    url.searchParams.set('utm_content', employerSearch);
    url.searchParams.set('utm_medium', 'programs_lp');
    url.searchParams.set('utm_source', 'ps_social');
    url.searchParams.set('partner', partnerParam);
    url.hash = 'results';
    if (employerSearch === 'sorry') {
      url.searchParams.set('employer', searchTerm);
    }
    window.location.href = url;
  };

  const handleSuggestionClick = clicked => {
    setSearch(clicked.label);
    performSearch(clicked.value);
  };

  const inputEntered = inputText => {
    if (inputText.length >= 2) {
      setOptionsShow(true);
    } else {
      setOptionsShow(false);
    }
    setSearch(inputText);
  };

  return (
    <div className="eligibility-search">
      <div className="eligibility-search__form">
        <Select
          options={
            optionsShow
              ? suggestions.map(suggestion => ({
                  value: suggestion['employer ID'],
                  label: suggestion['Employer Name'],
                }))
              : []
          }
          onChange={handleSuggestionClick}
          onInputChange={inputEntered}
          value={search}
          placeholder="Search for your employer"
          noOptionsMessage={() => (optionsShow ? `Search for ${search}` : 'Start typing to search')}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              performSearch(search);
            }
          }}
        />
      </div>
    </div>
  );
};
