import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import * as EmailValidator from 'email-validator';
import * as styles from './Apply1Form.module.scss';
import TextField from './formComponents/TextField';
import SelectField from './formComponents/SelectField';
import MCQuestion from './formComponents/MCQuestion';
import { sendContact, submitRegistrationForm } from '../api/registration';
import {
  customEventApply1Submit,
  customEventIdentifyUser,
  customEventPartialFormSubmit,
} from '../analytics/customEvents';
import validPASiteCodes from './validPASiteCodes.json';
import stateAndSiteCodes from './StateAndSiteCodeValues.json';
import { ErrorMessage } from './ErrorMessage';

export const Apply1FormProcessAsst = () => {
  const [cohortId, setCohortId] = useState('');
  const [programCode, setProgramCode] = useState('');
  const [thankYouRedirect, setThankYouRedirect] = useState('');
  const [ineligibleRedirect, setIneligibleRedirect] = useState('');

  const defaults = {
    firstName: 'Type your first name',
    lastName: 'Type your last name',
    email: 'Type your email',
    phone: 'e.g. (555) 555-5555',
  };

  // fields
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [amzCurrentEmployee, setAmzCurrentEmployee] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneMasked, setPhoneMasked] = useState('');
  const [amzState, setAmzState] = useState('');
  const [amzSiteCode, setAmzSiteCode] = useState('');
  const [amzSiteCodeFreeText, setAmzSiteCodeFreeText] = useState('');
  const [hasTimeCommitment, setHasTimeCommitment] = useState('');
  const [meetingTime, setMeetingTime] = useState([false, false, false, false]);
  const [currentRole, setCurrentRole] = useState('');
  const [currentRoleFreeText, setCurrentRoleFreeText] = useState('');
  const [roleTimeline, setRoleTimeline] = useState('');
  const [canRelocate, setCanRelocate] = useState('');
  const [hasHighSchoolMath, setHasHighSchoolMath] = useState('');
  const [educationLevel, setEducationLevel] = useState('');
  const [hasManagementExperience, setManagementExperience] = useState('');
  const [previousComputerPosition, setPreviousComputerPosition] = useState('');
  const [hasComputerLiteracy, setHasComputerLiteracy] = useState('');
  const [extraCurricular, setExtraCurricular] = useState('');
  const [programGoal, setProgramGoal] = useState('');
  const [programChallenges, setProgramChallenges] = useState('');
  const [conflictManagement, setConflictManagement] = useState('');
  const [linkedInProfileURL, setLinkedInProfileURL] = useState('');
  const [amazonPromotion, setAmazonPromotion] = useState('');
  const [textConsent, setTextConsent] = useState(false);
  const [callConsent, setCallConsent] = useState(false);
  const [termsConsent, setTermsConsent] = useState(false);
  const [mathQuizAnswers, setMathQuizAnswers] = useState(['', '', '', '', '', '', '', '', '', '']);
  const [mathQuizScore, setMathQuizScore] = useState(0.0);
  const [page, setPage] = useState(1);

  // errors
  const [emailError, setEmailError] = useState('');
  const [amzCurrentEmployeeError, setAmzCurrentEmployeeError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [amzStateError, setAmzStateError] = useState('');
  const [amzSiteCodeError, setAmzSiteCodeError] = useState('');
  const [hasTimeCommitmentError, setHasTimeCommitmentError] = useState('');
  const [meetingTimeError, setMeetingTimeError] = useState('');
  const [currentRoleError, setCurrentRoleError] = useState('');
  const [roleTimelineError, setRoleTimelineError] = useState('');
  const [canRelocateError, setCanRelocateError] = useState('');
  const [hasHighSchoolMathError, setHasHighSchoolMathError] = useState('');
  const [educationLevelError, setEducationLevelError] = useState('');
  const [managementExperienceError, setManagementExperienceError] = useState('');
  const [hasComputerLiteracyError, setHasComputerLiteracyError] = useState('');
  const [programGoalError, setProgramGoalError] = useState('');
  const [programChallengesError, setProgramChallengesError] = useState('');
  const [conflictManagementError, setConflictManagementError] = useState('');
  const [linkedInProfileURLError, setLinkedInProfileURLError] = useState('');
  const [amazonPromotionError, setAmazonPromotionError] = useState('');
  const [formError, setFormError] = useState(false);

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const stateNames = Object.getOwnPropertyNames(stateAndSiteCodes);
  const locationOptions = stateNames.map(state => ({ value: state, label: stateAndSiteCodes[state].state_name }));

  const siteCodeOptions = [
    { value: 'not-listed', label: 'My site code is not listed here' },
    { value: 'dont-know', label: "I don't know my site code" },
  ];
  const siteCodeList = validPASiteCodes.siteCodes;

  // remove duplicates
  const siteCodeListUnique = siteCodeList.filter((value, index, array) => array.indexOf(value) === index);
  const siteCodes = siteCodeListUnique.map(code => ({ value: code, label: code }));
  siteCodeOptions.unshift(...siteCodes);

  const redirect = React.useRef();

  useEffect(() => {
    const formOptions = document.querySelector('#apply1-form-process-asst');
    if (
      !formOptions.dataset.cohortid ||
      !formOptions.dataset.programcode ||
      !formOptions.dataset.thankyouredirect ||
      !formOptions.dataset.ineligibleredirect
    ) {
      // eslint-disable-next-line
      alert('Error: Form must have data-cohortId, data-programCode, and data-thankYouRedirect attributes.');
    } else {
      setCohortId(formOptions.dataset.cohortid);
      setProgramCode(formOptions.dataset.programcode);
      setThankYouRedirect(formOptions.dataset.thankyouredirect);
      setIneligibleRedirect(formOptions.dataset.ineligibleredirect);
      redirect.current = formOptions.dataset.thankyouredirect;
    }
  }, []);

  const handleChangeFirstName = e => {
    setFirstNameError('');
    setFirstName(e.target.value);
  };

  const handleChangeLastName = e => {
    setLastNameError('');
    setLastName(e.target.value);
  };

  const handleChangeEmail = e => {
    setEmailError('');
    setEmail(e.target.value);
  };

  const handleChangeAmzCurrentEmployee = e => {
    setAmzCurrentEmployeeError('');
    setAmzCurrentEmployee(e.target.value);
  };

  const handleChangePhone = e => {
    setPhoneError('');
    const phoneNum = e.target.value.replace(/\D/g, '');
    setPhone(phoneNum.length > 10 ? phoneNum.slice(0, 10) : phoneNum);
    // this masks the phone input to (xxx) xxx-xxxx
    const val = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    const phoneFormat = !val[2] ? val[1] : `(${val[1]}) ${val[2]}${val[3] ? `-${val[3]}` : ''}`;
    setPhoneMasked(phoneFormat);
  };

  const handleChangeAmzState = e => {
    setAmzStateError('');
    setAmzState(e.target.value);
  };

  const handleChangeAmzSiteCode = e => {
    setAmzSiteCodeError('');
    setAmzSiteCode(e.target.value);
  };

  const handletextConsent = () => {
    setTextConsent(!textConsent);
  };

  const handleCallConsent = () => {
    setCallConsent(!callConsent);
  };

  const handleTermsConsent = () => {
    setTermsConsent(!termsConsent);
  };

  const handleChangeHasTimeCommitment = e => {
    setHasTimeCommitmentError('');
    setHasTimeCommitment(e.target.value);
  };

  const handleMeetingTime = index => e => {
    const newMeetingTime = [...meetingTime];
    newMeetingTime[index] = e.target.checked;
    setMeetingTimeError('');
    setMeetingTime(newMeetingTime);
  };

  const handleChangeCurrentRole = e => {
    setCurrentRole(e.target.value);
  };

  const handleChangeRoleTimeline = e => {
    setRoleTimeline(e.target.value);
  };

  const handleChangeCanRelocate = e => {
    setCanRelocateError('');
    setCanRelocate(e.target.value);
  };

  const handleChangeHasHighSchoolMath = e => {
    setHasHighSchoolMath(e.target.value);
  };

  const handleChangeEducationLevel = e => {
    setEducationLevel(e.target.value);
  };

  const handleChangeManagementExperience = e => {
    setManagementExperience(e.target.value);
  };

  const handleChangePreviousComputerPosition = e => {
    setPreviousComputerPosition(e.target.value);
  };

  const handleChangeHasComputerLiteracy = e => {
    setHasComputerLiteracy(e.target.value);
  };

  const handleChangeExtraCurricular = e => {
    setExtraCurricular(e.target.value);
  };

  const handleChangeProgramGoal = e => {
    setProgramGoalError('');
    setProgramGoal(e.target.value);
  };

  const handleChangeProgramChallenges = e => {
    setProgramChallengesError('');
    setProgramChallenges(e.target.value);
  };

  const handleChangeConflictManagement = e => {
    setConflictManagementError('');
    setConflictManagement(e.target.value);
  };

  const handleLinkedInProfileURL = e => {
    setLinkedInProfileURLError('');
    setLinkedInProfileURL(e.target.value);
  };

  const handleChangeAmazonPromotion = e => {
    setAmazonPromotionError('');
    setAmazonPromotion(e.target.value);
  };

  const handleValidate = field => {
    let valid = true;
    if (field === 'email') {
      if (!email) {
        setEmailError(`Email is required`);
        valid = false;
      } else if (!EmailValidator.validate(email)) {
        setEmailError(`This email doesn't look right`);
        valid = false;
      } else {
        setEmailError('');
      }
    }
    if (field === 'firstName') {
      if (!firstName) {
        setFirstNameError('First name is required');
        valid = false;
      } else {
        setFirstNameError('');
      }
    }

    if (field === 'lastName') {
      if (!lastName) {
        setLastNameError('Last name is required');
        valid = false;
      } else {
        setLastNameError('');
      }
    }
    if (field === 'amzCurrentEmployee') {
      if (!amzCurrentEmployee) {
        setAmzCurrentEmployeeError('This is required');
        valid = false;
      } else {
        setAmzCurrentEmployeeError('');
      }
    }
    if (amzCurrentEmployee === 'yes') {
      if (field === 'phone') {
        if (!phone) {
          setPhoneError('Phone is required');
          valid = false;
        } else {
          setPhoneError('');
        }
      }

      if (field === 'amzState') {
        if (!amzState) {
          setAmzStateError('State is required');
          valid = false;
        } else {
          setAmzStateError('');
        }
      }
      if (field === 'amzSiteCode') {
        if (!amzSiteCode) {
          setAmzSiteCodeError('Site code is required');
          valid = false;
        } else {
          setAmzSiteCodeError('');
        }
      }
    }
    if (field === 'hasTimeCommitment') {
      if (!hasTimeCommitment) {
        setHasTimeCommitmentError('This is required');
        valid = false;
      } else {
        setHasTimeCommitmentError('');
      }
    }
    if (field === 'meetingTime') {
      if (!meetingTime.includes(true)) {
        setMeetingTimeError('This is required');
        valid = false;
      }
    }
    if (field === 'currentRole') {
      if (!currentRole) {
        setCurrentRoleError('This is required');
        valid = false;
      } else {
        setCurrentRoleError('');
      }
    }
    if (field === 'roleTimeline') {
      if (!roleTimeline) {
        setRoleTimelineError('This is required');
        valid = false;
      } else {
        setRoleTimelineError('');
      }
    }
    if (field === 'canRelocate') {
      if (!canRelocate) {
        setCanRelocateError('This is required');
        valid = false;
      } else {
        setCanRelocateError('');
      }
    }
    if (field === 'hasHighSchoolMath') {
      if (!hasHighSchoolMath) {
        setHasHighSchoolMathError('This is required');
        valid = false;
      } else {
        setHasHighSchoolMathError('');
      }
    }
    if (field === 'educationLevel') {
      if (!educationLevel) {
        setEducationLevelError('This is required');
        valid = false;
      } else {
        setEducationLevelError('');
      }
    }
    if (field === 'managementExperience') {
      if (!hasManagementExperience) {
        setManagementExperienceError('This is required');
        valid = false;
      } else {
        setManagementExperienceError('');
      }
    }
    if (field === 'hasComputerLiteracy') {
      if (!hasComputerLiteracy) {
        setHasComputerLiteracyError('This is required');
        valid = false;
      } else {
        setHasComputerLiteracyError('');
      }
    }
    if (field === 'programGoal') {
      if (!programGoal) {
        setProgramGoalError('This is required');
        valid = false;
      } else {
        setProgramGoalError('');
      }
    }
    if (field === 'programChallenges') {
      if (!programChallenges) {
        setProgramChallengesError('This is required');
        valid = false;
      } else {
        setProgramChallengesError('');
      }
    }
    if (field === 'conflictManagement') {
      if (!conflictManagement) {
        setConflictManagementError('This is required');
        valid = false;
      } else {
        setConflictManagementError('');
      }
    }
    if (field === 'amazonPromotion') {
      if (!amazonPromotion) {
        setAmazonPromotionError('This is required');
        valid = false;
      } else {
        setAmazonPromotionError('');
      }
    }

    return valid;
  };

  const isEligible = () => {
    if (amzCurrentEmployee === 'no') {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const qa = ['B', 'C', 'C', 'C', 'A', 'A', 'B', 'D', 'B', 'B'];
    let score = 0.0;
    for (let i = 0; i < qa.length; i++) {
      if (qa[i] === mathQuizAnswers[i]) {
        score++;
      }
    }
    setMathQuizScore(score / qa.length);
  }, [mathQuizAnswers]);

  const validateAll = () => {
    if (
      handleValidate('firstName') &&
      handleValidate('lastName') &&
      handleValidate('email') &&
      handleValidate('amzCurrentEmployee')
    ) {
      if (!isEligible()) {
        return true; // if not eligible, we don't need to validate the rest of the form, but we still want to submit the form
      }
      if (
        handleValidate('phone') &&
        handleValidate('amzState') &&
        handleValidate('amzSiteCode') &&
        handleValidate('hasTimeCommitment') &&
        handleValidate('meetingTime') &&
        handleValidate('currentRole') &&
        handleValidate('roleTimeline') &&
        handleValidate('canRelocate') &&
        handleValidate('hasHighSchoolMath') &&
        handleValidate('educationLevel') &&
        handleValidate('hasPreviousDataAnalysis') &&
        handleValidate('hasComputerLiteracy') &&
        handleValidate('programGoal') &&
        handleValidate('programChallenges') &&
        handleValidate('conflictManagement') &&
        handleValidate('amazonPromotion')
      ) {
        return true;
      }
    }
    return false;
  };

  const handleNextPage = () => {
    const valid = validateAll();
    if (valid && page === 1) {
      const formData = {
        first_name: firstName,
        last_name: lastName,
        email: email.trim().toLowerCase(),
        employer_name: `current_amazon: ${amzCurrentEmployee}`,
        call_consent: callConsent,
        text_conset: textConsent,
        phone,
      };
      sendContact(formData, status => {
        if (status.includes('contact_id')) {
          // console.log('contact submitted successfully');
        }
      });
      customEventIdentifyUser(email, firstName, lastName, phone); // calls analytics.identify
      customEventPartialFormSubmit({ form: 'amz_process_asst', ...formData });
      setPage(2);
      setFormError(false);
      window.scrollTo(0, 0);
    } else if (!valid) {
      setFormError(true);
    }
    if (page === 2) {
      setPage(1);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (validateAll()) {
      setFormError(false);
      setButtonDisabled(true);
      if (!isEligible()) {
        window.location.href = ineligibleRedirect + document.location.search;
        return;
      }
      const meetTimeTexts = ['weekday mornings', 'weekday evenings', 'weekends'];
      const meetTimes = meetingTime
        .map((time, index) => (time ? meetTimeTexts[index] : ''))
        .filter(time => time !== '');
      const formData = {
        cohort_id: cohortId,
        program_code: programCode,
        email: email.trim().toLowerCase(),
        first_name: firstName,
        last_name: lastName,
        phone,
        text_consent: textConsent,
        call_consent: callConsent,
        terms_consent: termsConsent,
        external_partner_data: {
          form_url: window.location.href,
          amazon_state: amzState,
          amazon_site_code: amzSiteCode === 'not-listed' ? amzSiteCodeFreeText : amzSiteCode,
          amazon_current_employee: amzCurrentEmployee,
          has_time_commitment: hasTimeCommitment,
          meeting_time: meetTimes.toString(),
          current_role: currentRole === 'other' ? currentRoleFreeText : currentRole,
          role_timeline: roleTimeline,
          relocate: canRelocate,
          has_high_school_math: hasHighSchoolMath,
          education_level: educationLevel,
          has_management_experience: hasManagementExperience,
          previous_computer_position: previousComputerPosition,
          has_computer_literacy: hasComputerLiteracy,
          amazon_extracurricular: extraCurricular,
          program_goal: programGoal,
          program_challenges: programChallenges,
          conflict_management: conflictManagement,
          linkedin_profile_url: linkedInProfileURL,
          amazon_promotion: amazonPromotion,
          amazon_math_quiz_version: 'v1',
          amazon_math_quiz_answers: mathQuizAnswers.toString(),
          amazon_math_quiz_score: mathQuizScore,
        },
      };

      // console.log('formData', formData);
      // console.log('redirect', thankYouRedirect);

      customEventIdentifyUser(email, firstName, lastName, phone);
      customEventApply1Submit(cohortId, programCode, { email });
      submitRegistrationForm(formData, status => {
        if (status.includes('contact_id')) {
          // console.log('Form submitted successfully');
          window.location.href = thankYouRedirect + document.location.search;
        }
      });
    } else {
      setFormError(true);
      console.log('Form is not valid');
    }
  };

  const formFieldClass = classnames(styles['form-element'], styles['inline-style']);
  const wideSelectFieldClass = classnames(styles['form-element'], styles['wide-select']);
  // const narrowSelectFieldClass = classnames(styles['form-element'], styles['narrow-select']);
  const extraWideSelectFieldClass = classnames(styles['form-element'], styles['extra-wide-select']);
  return (
    <div className={styles.apply1Form}>
      <form onSubmit={handleSubmit}>
        <input type="hidden" name="cohort_id" value={cohortId} />
        <input type="hidden" name="program_code" value={programCode} />
        {page === 1 && (
          <>
            <h1> Step 1 - About You</h1>
            <div className={styles['form-content']}>
              <TextField
                fieldClass={formFieldClass}
                fieldName="firstName"
                fieldType="text"
                labelText="First name*"
                onBlur={() => {
                  handleValidate('firstName');
                }}
                onChange={handleChangeFirstName}
                textValue={firstName}
                error={firstNameError}
                placeholder={defaults.firstName}
              />
              <TextField
                fieldClass={formFieldClass}
                fieldName="lastName"
                fieldType="text"
                labelText="Last name*"
                onBlur={() => {
                  handleValidate('lastName');
                }}
                onChange={handleChangeLastName}
                textValue={lastName}
                error={lastNameError}
                placeholder={defaults.lastName}
              />
            </div>
            <div className={styles['form-content']}>
              <TextField
                fieldClass={formFieldClass}
                fieldName="email"
                fieldType="email"
                labelText="Email*"
                onBlur={() => {
                  handleValidate('email');
                }}
                onChange={handleChangeEmail}
                textValue={email}
                error={emailError}
                placeholder={defaults.email}
              />
              <TextField
                fieldClass={formFieldClass}
                fieldType="tel"
                fieldName="phone"
                labelText="Phone*"
                onBlur={() => {
                  handleValidate('phone');
                }}
                onChange={handleChangePhone}
                textValue={phoneMasked}
                error={phoneError}
                placeholder={defaults.phone}
              />
            </div>
            <div className={styles['form-content']}>
              <SelectField
                fieldClass={wideSelectFieldClass}
                fieldName="amzCurrentEmployee"
                labelText="Are you an Amazon employee?*"
                onBlur={() => {
                  handleValidate('amzCurrentEmployee');
                }}
                onChange={handleChangeAmzCurrentEmployee}
                error={amzCurrentEmployeeError}
                options={[
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ]}
                value={amzCurrentEmployee}
              />
            </div>
            <div className={styles['form-content']}>
              {amzCurrentEmployee === 'no' && (
                <div className={formFieldClass}>
                  <label style={{ color: 'red' }}>
                    <strong>
                      We are sorry, but this program is only available to Amazon employees. Please note that if you are
                      not a current Amazon employee, your application will not be considered.
                    </strong>
                  </label>
                </div>
              )}
            </div>
            <div className={styles['form-content']}>
              <SelectField
                fieldClass={wideSelectFieldClass}
                fieldName="amzState"
                labelText="What state do you work in?*"
                onBlur={() => {
                  handleValidate('amzState');
                }}
                onChange={handleChangeAmzState}
                error={amzStateError}
                options={locationOptions}
                value={amzState}
              />
            </div>
            <div className={styles['form-content']}>
              <SelectField
                fieldClass={wideSelectFieldClass}
                fieldName="amzSiteCode"
                labelText="What is your Amazon site code?*"
                onBlur={() => {
                  handleValidate('amzSiteCode');
                }}
                onChange={handleChangeAmzSiteCode}
                error={amzSiteCodeError}
                options={siteCodeOptions}
                value={amzSiteCode}
              />
            </div>
            {amzSiteCode === 'not-listed' && (
              <div className={styles['form-content']}>
                <TextField
                  fieldClass={formFieldClass}
                  fieldName="amzSiteCodeFreeText"
                  fieldType="text"
                  labelText="If your site code is not listed, what is your site code?"
                  onChange={e => setAmzSiteCodeFreeText(e.target.value)}
                  textValue={amzSiteCodeFreeText}
                />
              </div>
            )}
            <div className={styles['form-content']}>
              <SelectField
                fieldClass={wideSelectFieldClass}
                fieldName="hasTimeCommitment"
                labelText="Are you able to commit to 8-10 hours of study per week between July and October, 2024?*"
                onBlur={() => {
                  handleValidate('hasTimeCommitment');
                }}
                onChange={handleChangeHasTimeCommitment}
                error={hasTimeCommitmentError}
                options={[
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ]}
                value={hasTimeCommitment}
              />
            </div>
            <div className={styles['form-content']}>
              <div className={formFieldClass}>
                <label htmlFor="meetingTime" className={styles['form-label']}>
                  As part of this certificate, you will be required to attend live, online sessions twice each month
                  (via Zoom). When are the optimal times for you to attend live sessions? (select all that apply)
                </label>
                {meetingTimeError && <div className={styles['form-error']}>{meetingTimeError}</div>}
                <div className={styles['field-wrapper']}>
                  <input
                    type="checkbox"
                    name="meetingTime"
                    id="weekday_mornings"
                    value="weekday_mornings"
                    checked={meetingTime[0]}
                    onChange={handleMeetingTime(0)}
                  />
                  <label htmlFor="weekday_mornings" className={styles['check-label']}>
                    <div className={styles['check-box-text']}>Weekday Mornings</div>
                  </label>
                </div>
                <div className={styles['field-wrapper']}>
                  <input
                    type="checkbox"
                    name="meetingTime"
                    id="weekday_evenings"
                    value="weekday_evenings"
                    checked={meetingTime[1]}
                    onChange={handleMeetingTime(1)}
                  />
                  <label htmlFor="weekday_evenings" className={styles['check-label']}>
                    <div className={styles['check-box-text']}>Weekday Evenings</div>
                  </label>
                </div>
                <div className={styles['field-wrapper']}>
                  <input
                    type="checkbox"
                    name="meetingTime"
                    id="weekends"
                    value="weekends"
                    checked={meetingTime[2]}
                    onChange={handleMeetingTime(2)}
                  />
                  <label htmlFor="weekends" className={styles['check-label']}>
                    <div className={styles['check-box-text']}>Weekends</div>
                  </label>
                </div>
                <div className={styles['field-wrapper']}>
                  <input
                    type="checkbox"
                    name="meetingTime"
                    id="cannot-commit"
                    value="cannot-commit"
                    checked={meetingTime[3]}
                    onChange={handleMeetingTime(3)}
                  />
                  <label htmlFor="cannot-commit" className={styles['check-label']}>
                    <div className={styles['check-box-text']}>
                      I cannot commit to attending live, online sessions via Zoom during the program (July-October)
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className={styles['form-content']}>
              <SelectField
                fieldClass={extraWideSelectFieldClass}
                fieldName="currentRole"
                labelText="What is your current role at Amazon?*"
                onBlur={() => {
                  handleValidate('currentRole');
                }}
                onChange={handleChangeCurrentRole}
                error={currentRoleError}
                options={[
                  { label: 'Associate', value: 'associate' },
                  { label: 'Process Assistant', value: 'process assistant' },
                  { label: 'Other', value: 'other' },
                ]}
                value={currentRole}
              />
            </div>
            {currentRole === 'other' && (
              <div className={styles['form-content']}>
                <TextField
                  fieldClass={formFieldClass}
                  fieldName="currentRoleFreeText"
                  fieldType="text"
                  labelText="If your role was not listed, what is your role/job title?"
                  onChange={e => setCurrentRoleFreeText(e.target.value)}
                  textValue={currentRoleFreeText}
                />
              </div>
            )}
            <div className={styles['form-content']}>
              <SelectField
                fieldClass={extraWideSelectFieldClass}
                fieldName="roleTimeline"
                labelText="What is your timeline for wanting to apply to an operations leadership role (team lead, supervisor)?*"
                onBlur={() => {
                  handleValidate('roleTimeline');
                }}
                onChange={handleChangeRoleTimeline}
                error={roleTimelineError}
                options={[
                  { label: 'As soon as possible', value: 'as soon as possible' },
                  { label: 'Within the next 6-12 months', value: 'within 6-12 months' },
                  {
                    label: "I'm not interested in becoming an operations leader at this time",
                    value: 'not interested in being an operations leader at this time',
                  },
                  { label: "I'm not sure", value: 'not sure' },
                  { label: "I'm already an operations leader", value: 'already an operations leader' },
                ]}
                value={roleTimeline}
              />
            </div>
            <div className={styles['form-content']}>
              <SelectField
                fieldClass={wideSelectFieldClass}
                fieldName="canRelocate"
                labelText=" Are you open to moving to pursue your next career opportunity?*"
                onBlur={() => {
                  handleValidate('canRelocate');
                }}
                onChange={handleChangeCanRelocate}
                error={canRelocateError}
                options={[
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ]}
                value={canRelocate}
              />
            </div>
            <div className={styles['form-content']}>
              <SelectField
                fieldClass={wideSelectFieldClass}
                fieldName="hasHighSchoolMath"
                labelText="Have you completed high school level algebra?*"
                onBlur={() => {
                  handleValidate('hasHighSchoolMath');
                }}
                onChange={handleChangeHasHighSchoolMath}
                error={hasHighSchoolMathError}
                options={[
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ]}
                value={hasHighSchoolMath}
              />
            </div>
            <div className={styles['form-content']}>
              <SelectField
                fieldClass={extraWideSelectFieldClass}
                fieldName="educationLevel"
                labelText="What is your highest level of education?*"
                onBlur={() => {
                  handleValidate('educationLevel');
                }}
                onChange={handleChangeEducationLevel}
                error={educationLevelError}
                options={[
                  { label: 'Less than high school', value: 'less than high school' },
                  { label: 'Some high school', value: 'some high school' },
                  { label: 'High school graduate or equivalent (GED)', value: 'high school graduate' },
                  { label: 'Some college', value: 'some college' },
                  { label: 'Associate degree (2-year college program)', value: 'associate' },
                  { label: "Bachelor's degree (4-year college program)", value: 'bachelor' },
                  { label: "Master's degree or professional degree (MA, MS, MD, lawyer, Ph.D)", value: 'advanced' },
                ]}
                value={educationLevel}
              />
            </div>
            <div className={styles['form-content']}>
              <SelectField
                fieldClass={wideSelectFieldClass}
                fieldName="managementExperience"
                labelText="Do you have previous experience as a supervisor or manager?*"
                onBlur={() => {
                  handleValidate('managementExperience');
                }}
                onChange={handleChangeManagementExperience}
                error={managementExperienceError}
                options={[
                  { label: 'No', value: 'no' },
                  { label: 'Yes, I have some management experience (one year or less)', value: 'less than one year' },
                  { label: 'Yes, I have more than one year of management experience', value: 'more than one year' },
                ]}
                value={hasManagementExperience}
              />
            </div>
            <div className={styles['form-content']}>
              <SelectField
                fieldClass={wideSelectFieldClass}
                fieldName="hasComputerLiteracy"
                labelText="Have you ever held a job where you needed to use a computer for the majority of your tasks?*"
                onBlur={() => {
                  handleValidate('hasComputerLiteracy');
                }}
                onChange={handleChangeHasComputerLiteracy}
                error={hasComputerLiteracyError}
                options={[
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ]}
                value={hasComputerLiteracy}
              />
            </div>
            <div className={styles['form-content']}>
              <TextField
                fieldClass={formFieldClass}
                fieldName="previousComputerPosition"
                fieldType="text"
                labelText="If yes, what was your job title and company?"
                onChange={handleChangePreviousComputerPosition}
                textValue={previousComputerPosition}
                placeholder="Type your answer"
              />
            </div>
            <div className={styles['form-content']}>
              <div className={formFieldClass}>
                <label htmlFor="programGoal" className={styles['form-label']}>
                  What is your motivation for completing this program?*
                </label>
                <div className={styles['field-wrapper']}>
                  <textarea
                    type="text"
                    id="programGoal"
                    placeholder="Type your answer here..."
                    onChange={handleChangeProgramGoal}
                    onBlur={() => {
                      handleValidate('programGoal');
                    }}
                    name="programGoal"
                    value={programGoal}
                    maxLength={500}
                  />
                  {programGoalError && ErrorMessage(programGoalError)}
                </div>
              </div>
            </div>
            <div className={styles['form-content']}>
              <div className={formFieldClass}>
                <label htmlFor="programChallenge" className={styles['form-label']}>
                  What do you think might be your biggest challenge in completing this program?*
                </label>
                <div className={styles['field-wrapper']}>
                  <textarea
                    type="text"
                    id="programChallenges"
                    placeholder="Type your answer here..."
                    onChange={handleChangeProgramChallenges}
                    onBlur={() => {
                      handleValidate('programChallenges');
                    }}
                    name="programChallenges"
                    value={programChallenges}
                    maxLength={500}
                  />
                  {programChallengesError && ErrorMessage(programChallengesError)}
                </div>
              </div>
            </div>
            <div className={styles['form-content']}>
              <div className={formFieldClass}>
                <label htmlFor="conflictManagement" className={styles['form-label']}>
                  Describe a situation where you had to manage a conflict or misunderstanding with someone. How did you
                  handle it?*
                </label>
                <div className={styles['field-wrapper']}>
                  <textarea
                    type="text"
                    id="conflictManagement"
                    placeholder="Type your answer here..."
                    onChange={handleChangeConflictManagement}
                    onBlur={() => {
                      handleValidate('conflictManagement');
                    }}
                    name="conflictManagement"
                    value={conflictManagement}
                    maxLength={500}
                  />
                  {conflictManagementError && ErrorMessage(conflictManagementError)}
                </div>
              </div>
            </div>
            <div className={styles['form-content']}>
              <div className={formFieldClass}>
                <label htmlFor="extraCurricular" className={styles['form-label']}>
                  What, if any, extra responsibilities have you taken on in your role?
                </label>
                <div className={styles['field-wrapper']}>
                  <textarea
                    type="text"
                    id="extraCurricular"
                    placeholder="Type your answer here..."
                    onChange={handleChangeExtraCurricular}
                    name="extraCurricular"
                    value={extraCurricular}
                    maxLength={500}
                  />
                </div>
              </div>
            </div>
            <div className={styles['form-content']}>
              <TextField
                fieldClass={formFieldClass}
                fieldName="linkedInProfileURL"
                fieldType="text"
                labelText="Please provide a link to your LinkedIn profile, if you have one."
                onBlur={() => {
                  handleValidate('linkedIn');
                }}
                onChange={handleLinkedInProfileURL}
                textValue={linkedInProfileURL}
                error={linkedInProfileURLError}
                placeholder="Paste your LinkedIn URL here"
              />
            </div>
            <div className={styles['form-content']}>
              <SelectField
                fieldClass={wideSelectFieldClass}
                fieldName="amazonPromotion"
                labelText="I understand that if I take this program, it does not guarantee that I will get promoted into a new or higher-paying job at Amazon.*"
                onBlur={() => {
                  handleValidate('amazonPromotion');
                }}
                onChange={handleChangeAmazonPromotion}
                error={amazonPromotionError}
                options={[
                  { label: 'Yes, I understand', value: 'yes' },
                  { label: 'No', value: 'no' },
                ]}
                value={amazonPromotion}
              />
            </div>
            <div className={styles['form-content']}>
              <div className={formFieldClass}>
                <div className={styles['field-wrapper']}>
                  <label htmlFor="textConsent" className={styles['check-label']}>
                    <input
                      type="checkbox"
                      name="text_consent"
                      id="field[15]"
                      checked={textConsent}
                      onChange={handletextConsent}
                      defaultValue="false"
                    />
                    <div className={styles['check-box-text']}>
                      I provide my consent for Pathstream or its partners to contact me using text messaging at the
                      phone number provided above.
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className={styles['form-content']}>
              <div className={formFieldClass}>
                <div className={styles['field-wrapper']}>
                  <label htmlFor="callConsent" className={styles['check-label']}>
                    <input
                      type="checkbox"
                      name="call_consent"
                      id="field[15]"
                      checked={callConsent}
                      onChange={handleCallConsent}
                      defaultValue="false"
                    />
                    <div className={styles['check-box-text']}>
                      I provide my consent for Pathstream or its partners to contact me using calls at the phone number
                      provided above.
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className={styles['form-content']}>
              <div className={formFieldClass}>
                <div className={styles['field-wrapper']}>
                  <label htmlFor="termsConsent" className={styles['check-label']}>
                    <input
                      type="checkbox"
                      name="terms_consent"
                      id="field[15]"
                      checked={termsConsent}
                      onChange={handleTermsConsent}
                      defaultValue="false"
                      required
                    />
                    <div className={styles['check-box-text']}>
                      I agree to Pathstream&apos;s{' '}
                      <a href="https://pathstream.com/terms-of-use" target="blank">
                        terms of use
                      </a>{' '}
                      and <a href="https://pathstream.com/privacy-policy">privacy policy</a>.
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <br />
            {formError && (
              <div className={styles['form-error']}>
                You are missing a required field. Please check your responses to continue.
              </div>
            )}
            <div className={styles['form-buttons']}>
              <div className={classnames(styles['button-wrapper'], styles['inline-style'])}>
                {buttonDisabled && 'Loading...'}
                {!buttonDisabled && (
                  <button
                    id="form-164-next"
                    className={styles.next}
                    type="button"
                    disabled={buttonDisabled}
                    onClick={handleNextPage}>
                    Next {`>`}
                  </button>
                )}
              </div>
            </div>
          </>
        )}
        {page === 2 && (
          <>
            <h1> Step 2 - Math Assessment</h1>
            <div className={styles['quiz-instructions']}>
              <p>
                Please answer the following questions, which will provide a baseline assessment for some of the types of
                mathematical questions Process Assistants and other types of Team Leads handle in their day to day jobs.
              </p>
            </div>
            <MCQuestion
              questionText="1. A forklift operator needs to move pallets from the receiving area to storage. If each trip takes 10 minutes and the operator has 5 hours to work, excluding a 30-minute lunch break, how many trips can the operator make?"
              questionNumber={1}
              answerOptions={['25', '27', '32', '33']}
              currentAnswers={mathQuizAnswers}
              updateAnswers={setMathQuizAnswers}
            />
            <MCQuestion
              questionText="2. A warehouse receives an order for 800 items that need to be packed into small, medium, and large boxes. If each box can hold 10, 20, and 50 items, how many large boxes are needed if 8 small boxes and 6 medium boxes are packed full, in order to ship the order?"
              questionNumber={2}
              answerOptions={['8', '10', '12', '14']}
              currentAnswers={mathQuizAnswers}
              updateAnswers={setMathQuizAnswers}
            />
            <MCQuestion
              questionText="3. The warehouse operates two shifts: morning and night. If the morning shift processes orders 3 times faster than the night shift and together they process 900 orders, how many orders does the morning shift process?"
              questionNumber={3}
              answerOptions={['300', '325', '675', '800']}
              currentAnswers={mathQuizAnswers}
              updateAnswers={setMathQuizAnswers}
            />
            <MCQuestion
              questionText="4. Warehouse A is 20% smaller than Warehouse B. If Warehouse A currently contains 7,000 boxes, and is ⅔ full, how many boxes fit in Warehouse B?"
              questionNumber={4}
              answerOptions={['10,250', '12,500', '13,125', '15,875']}
              currentAnswers={mathQuizAnswers}
              updateAnswers={setMathQuizAnswers}
            />
            <MCQuestion
              questionText="5. There are three types of tasks, A, B, and C, that take 1, 2 and 3 minutes, respectively, to complete. How many tasks can be completed in 8 hours by one person that spends 20% of their time on task A, 70% of the time on task B, and 10% of their time on task C?"
              questionNumber={5}
              answerOptions={['280', '300', '320', '340']}
              currentAnswers={mathQuizAnswers}
              updateAnswers={setMathQuizAnswers}
            />
            <MCQuestion
              questionText="6. A team leader finds that an error occurred in the order processing, and 120 orders need to be reprocessed within 8 hours to meet the shipping deadline. If each order takes 15 minutes to process, how many workers are needed to complete this task on time if each worker processes orders at the same rate?"
              questionNumber={6}
              answerOptions={['4', '5', '6', '7']}
              currentAnswers={mathQuizAnswers}
              updateAnswers={setMathQuizAnswers}
            />
            <MCQuestion
              questionText="7. Warehouse A and Warehouse B are collaborating on a project. Warehouse A can complete the project in 30 days, but Warehouse B can do it in 45 days. If they work together, how long will it take them to complete the project?"
              questionNumber={7}
              answerOptions={['15', '18', '37.5', '75']}
              currentAnswers={mathQuizAnswers}
              updateAnswers={setMathQuizAnswers}
            />
            <MCQuestion
              questionText="8. A manager has run a report to determine how long 5 team members spent completing their most recent tasks, which are 55 min, 1 hr, 1.1 hr, 1 ⅛ hr, and 1 hr 10 min. What is the average (mean) time the team takes to complete the task?"
              questionNumber={8}
              answerOptions={[
                '1 hr, 1 min, 12 sec',
                '1 hr, 2 min, 6 sec',
                '1 hr, 2 min, 18 sec',
                '1 hr, 3 min, 42 sec',
              ]}
              currentAnswers={mathQuizAnswers}
              updateAnswers={setMathQuizAnswers}
            />
            <MCQuestion
              questionText="9. A warehouse needs to purchase new forklifts for moving pallets. Each forklift costs $15,000, and the warehouse has been given a budget of $130,000 for this purchase. If the warehouse also needs to set aside 20% of its budget for maintenance costs, how many forklifts can the warehouse afford to purchase?"
              questionNumber={9}
              answerOptions={['5', '6', '7', '8']}
              currentAnswers={mathQuizAnswers}
              updateAnswers={setMathQuizAnswers}
            />
            <MCQuestion
              questionText="10. The warehouse plans to allocate a new area for storing boxes that are 8 cubic feet. The boxes are perfect cubes (length, width, and height are all the same) and boxes cannot be stacked on top of one another. The warehouse has allocated a rectangular area measuring 16 yards by 20 yards, how many boxes can be stored in this area?"
              questionNumber={10}
              answerOptions={['380', '720', '1020', '1280']}
              currentAnswers={mathQuizAnswers}
              updateAnswers={setMathQuizAnswers}
            />
            {formError && (
              <div className={styles['form-error']}>You are missing something. Please check your responses.</div>
            )}
            <div className={styles['form-buttons']}>
              <div className={classnames(styles['button-wrapper'], styles['inline-style'])}>
                {!buttonDisabled && (
                  <button
                    id="form-164-back"
                    className={styles.next}
                    type="button"
                    disabled={buttonDisabled}
                    onClick={handleNextPage}>
                    {`< `}Back
                  </button>
                )}
              </div>
              <div className={classnames(styles['button-wrapper'], styles['inline-style'])}>
                {buttonDisabled && 'Processing application...'}
                {!buttonDisabled && (
                  <button id="form-164-submit" className={styles.submit} type="submit" disabled={buttonDisabled}>
                    Apply
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </form>
    </div>
  );
};
