import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import * as EmailValidator from 'email-validator';
import * as styles from './Apply1Form.module.scss';
import TextField from './formComponents/TextField';
import SelectField from './formComponents/SelectField';
import { submitRegistrationForm } from '../api/registration';
import { customEventApply1Submit, customEventIdentifyUser } from '../analytics/customEvents';
import stateAndSiteCodes from './StateAndSiteCodeValues.json';

export const Apply1FormSalesOps = () => {
  const [cohortId, setCohortId] = useState('');
  const [programCode, setProgramCode] = useState('');
  const [simpleForm, setSimpleForm] = useState(false);
  const [thankYouRedirect, setThankYouRedirect] = useState('');

  const defaults = {
    firstName: 'Type your first name',
    lastName: 'Type your last name',
    email: 'Type your email',
    phone: 'e.g. (555) 555-5555',
  };

  const stateNames = Object.getOwnPropertyNames(stateAndSiteCodes);
  const locationOptions = stateNames.map(state => ({ value: state, label: stateAndSiteCodes[state].state_name }));

  const amzCurrentGoalsOptions = [
    { value: 'Further my education', label: 'Further my education' },
    { value: 'Learn new skills', label: 'Learn new skills' },
    { value: 'Find a new job', label: 'Find a new job' },
    { value: 'Increase my salary', label: 'Increase my salary' },
    { value: 'Earn a promotion', label: 'Earn a promotion' },
    { value: 'Change industries or careers', label: 'Change industries or careers' },
    { value: 'Not sure', label: "I'm not sure" },
  ];
  const amzCurrentInterestsOptions = [
    { value: 'Sales Operations', label: 'Sales Operations' },
    { value: 'Data Analytics', label: 'Data Analytics' },
    { value: 'Project Management', label: 'Project Management' },
    { value: 'Digital Marketing', label: 'Digital Marketing' },
    { value: 'Not sure', label: "I'm not sure" },
  ];

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [amzCurrentEmployee, setAmzCurrentEmployee] = useState('');
  const [amzCurrentGoals, setAmzCurrentGoals] = useState('');
  const [amzCurrentInterests, setAmzCurrentInterests] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneMasked, setPhoneMasked] = useState('');
  const [amzState, setAmzState] = useState('');
  const [amzSiteCode, setAmzSiteCode] = useState('');
  const [phoneConsent, setPhoneConsent] = useState(true);
  const [emailError, setEmailError] = useState('');
  const [amzCurrentEmployeeError, setAmzCurrentEmployeeError] = useState('');
  const [amzCurrentGoalsError, setAmzCurrentGoalsError] = useState('');
  const [amzCurrentInterestsError, setAmzCurrentInterestsError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [amzStateError, setAmzStateError] = useState('');
  const [amzSiteCodeError, setAmzSiteCodeError] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);

  let siteCodeOptions = [];

  if (amzState) {
    const siteCodeList = stateAndSiteCodes[amzState].site_codes;
    if (siteCodeList[0] === 'none') {
      siteCodeOptions = [{ value: 'none', label: 'N/A' }];
    } else if (siteCodeList) {
      const siteCodeListUnique = siteCodeList.filter((value, index, array) => array.indexOf(value) === index);
      siteCodeOptions = siteCodeListUnique.map(code => ({ value: code, label: code }));
    }
  }

  const redirect = React.useRef();

  useEffect(() => {
    const formOptions = document.querySelector('#apply1-form-sales-ops');
    if (!formOptions.dataset.cohortid || !formOptions.dataset.programcode || !formOptions.dataset.thankyouredirect) {
      // eslint-disable-next-line
      alert('Error: Form must have data-cohortId, data-programCode, and data-thankYouRedirect attributes.');
    } else {
      setCohortId(formOptions.dataset.cohortid);
      setProgramCode(formOptions.dataset.programcode);
      setSimpleForm(formOptions.dataset.simpleform === 'true');
      setThankYouRedirect(formOptions.dataset.thankyouredirect);
      redirect.current = formOptions.dataset.thankyouredirect;
    }
  }, []);

  const handleChangeFirstName = e => {
    setFirstName(e.target.value);
  };

  const handleChangeLastName = e => {
    setLastName(e.target.value);
  };

  const handleChangeEmail = e => {
    setEmailError('');
    setEmail(e.target.value);
  };

  const handleChangeAmzCurrentEmployee = e => {
    setAmzCurrentEmployeeError('');
    setAmzCurrentEmployee(e.target.value);
    if (e.target.value === 'no') {
      setThankYouRedirect('https://pathstream.com/programs/');
    } else {
      setThankYouRedirect(redirect.current);
    }
  };

  const handleChangePhone = e => {
    setPhoneError('');
    const phoneNum = e.target.value.replace(/\D/g, '');
    setPhone(phoneNum.length > 10 ? phoneNum.slice(0, 10) : phoneNum);
    // this masks the phone input to (xxx) xxx-xxxx
    const val = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    const phoneFormat = !val[2] ? val[1] : `(${val[1]}) ${val[2]}${val[3] ? `-${val[3]}` : ''}`;
    setPhoneMasked(phoneFormat);
  };

  const handleChangeAmzState = e => {
    setAmzStateError('');
    setAmzState(e.target.value);
  };

  const handleChangeAmzSiteCode = e => {
    setAmzSiteCodeError('');
    setAmzSiteCode(e.target.value);
  };

  const handlePhoneConsent = () => {
    setPhoneConsent(!phoneConsent);
  };

  const handleChangeAmzCurrentGoals = e => {
    setAmzCurrentGoalsError('');
    setAmzCurrentGoals(e.target.value);
  };

  const handleChangeAmzCurrentInterests = e => {
    setAmzCurrentInterestsError('');
    setAmzCurrentInterests(e.target.value);
  };

  const isIncomplete = () => {
    if (
      !email ||
      !firstName ||
      !lastName ||
      !amzCurrentEmployee ||
      (amzCurrentEmployee === 'yes' && (!phone || !amzState || !amzSiteCode)) ||
      (amzCurrentEmployee === 'no' && (!amzCurrentGoals || !amzCurrentInterests))
    ) {
      return true;
    }
    return false;
  };

  const handleValidate = field => {
    let valid = true;

    if (field === 'email') {
      if (!email) {
        setEmailError(`Email is required`);
        valid = false;
      } else if (!EmailValidator.validate(email)) {
        setEmailError(`This email doesn't look right`);
        valid = false;
      } else {
        setEmailError('');
      }
    }
    if (field === 'firstName') {
      if (!firstName) {
        setFirstNameError('First name is required');
        valid = false;
      } else {
        setFirstNameError('');
      }
    }

    if (field === 'lastName') {
      if (!lastName) {
        setLastNameError('Last name is required');
        valid = false;
      } else {
        setLastNameError('');
      }
    }
    if (!simpleForm) {
      if (field === 'amzCurrentEmployee') {
        if (!amzCurrentEmployee) {
          setAmzCurrentEmployeeError('This is required');
          valid = false;
        } else {
          setAmzCurrentEmployeeError('');
        }
      }
      if (amzCurrentEmployee === 'yes') {
        if (field === 'phone') {
          if (!phone) {
            setPhoneError('Phone is required');
            valid = false;
          } else {
            setPhoneError('');
          }
        }

        if (field === 'amzState') {
          if (!amzState) {
            setAmzStateError('State is required');
            valid = false;
          } else {
            setAmzStateError('');
          }
        }

        if (field === 'amzSiteCode') {
          if (!amzSiteCode) {
            setAmzSiteCodeError('Site Code is required');
            valid = false;
          } else {
            setAmzSiteCodeError('');
          }
        }
      } else if (amzCurrentEmployee === 'no') {
        if (field === 'amzCurrentGoals') {
          if (!amzCurrentGoals) {
            setAmzCurrentGoalsError('This is required');
            valid = false;
          } else {
            setAmzCurrentGoalsError('');
          }
        }
        if (field === 'amzCurrentInterests') {
          if (!amzCurrentInterests) {
            setAmzCurrentInterestsError('This is required');
            valid = false;
          } else {
            setAmzCurrentInterestsError('');
          }
        }
      }
    }

    return valid;
  };

  const validateAll = () => {
    if (
      handleValidate('email') &&
      handleValidate('phone') &&
      handleValidate('firstName') &&
      handleValidate('lastName') &&
      handleValidate('amzState') &&
      handleValidate('amzSiteCode') &&
      handleValidate('amzCurrentEmployee') &&
      handleValidate('amzCurrentGoals') &&
      handleValidate('amzCurrentInterests')
    ) {
      return true;
    }
    return false;
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (validateAll()) {
      setButtonDisabled(true);
      const formData = {
        cohort_id: cohortId,
        program_code: programCode,
        email,
        first_name: firstName,
        last_name: lastName,
        phone,
        phone_consent: phoneConsent,
        entry: 'amazon_career_quiz_lead',
        external_partner_data: {
          amazon_state: amzState,
          amazon_site_code: amzSiteCode,
          amazon_current_employee: amzCurrentEmployee,
          current_goals: amzCurrentGoals,
          current_interests: amzCurrentInterests,
        },
      };

      customEventIdentifyUser(email, firstName, lastName, phone);
      customEventApply1Submit(cohortId, programCode, { variation: simpleForm ? 'b' : 'a', email });
      submitRegistrationForm(formData, simpleForm, status => {
        if (status.includes('contact_id')) {
          window.location.href = thankYouRedirect;
        }
      });
    }
  };

  const formFieldClass = classnames(styles['form-element'], styles['inline-style']);
  const wideSelectFieldClass = classnames(styles['form-element'], styles['wide-select']);
  const narrowSelectFieldClass = classnames(styles['form-element'], styles['narrow-select']);

  return (
    <div className={styles.apply1Form}>
      <form onSubmit={handleSubmit}>
        <input type="hidden" name="cohort_id" value={cohortId} />
        <input type="hidden" name="program_code" value={programCode} />
        <div className={styles['form-content']}>
          <TextField
            fieldClass={formFieldClass}
            fieldName="firstName"
            fieldType="text"
            labelText="First name*"
            onBlur={() => {
              // handleValidate('firstName');
            }}
            onChange={handleChangeFirstName}
            textValue={firstName}
            error={firstNameError}
            placeholder={defaults.firstName}
          />
          <TextField
            fieldClass={formFieldClass}
            fieldName="lastName"
            fieldType="text"
            labelText="Last name*"
            onBlur={() => {
              // handleValidate('lastName');
            }}
            onChange={handleChangeLastName}
            textValue={lastName}
            error={lastNameError}
            placeholder={defaults.lastName}
          />
          <TextField
            fieldClass={formFieldClass}
            fieldName="email"
            fieldType="email"
            labelText="Email*"
            onBlur={() => {
              handleValidate('email');
            }}
            onChange={handleChangeEmail}
            textValue={email}
            error={emailError}
            placeholder={defaults.email}
          />
          {!simpleForm && (
            <SelectField
              fieldClass={narrowSelectFieldClass}
              fieldName="amzCurrentEmployee"
              labelText="Are you an Amazon employee?"
              onBlur={() => {
                // handleValidate('amzCurrentEmployee');
              }}
              onChange={handleChangeAmzCurrentEmployee}
              error={amzCurrentEmployeeError}
              options={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
              ]}
            />
          )}
        </div>
        <div className={styles['form-content']}>
          {!simpleForm && amzCurrentEmployee === 'yes' && (
            <>
              <TextField
                fieldClass={formFieldClass}
                fieldName="phone"
                fieldType="tel"
                labelText="Phone*"
                onBlur={() => {
                  handleValidate('phone');
                }}
                onChange={handleChangePhone}
                textValue={phoneMasked}
                error={phoneError}
                placeholder={defaults.phone}
              />
              <SelectField
                fieldClass={narrowSelectFieldClass}
                fieldName="amzState"
                labelText="State*"
                onBlur={() => {
                  // handleValidate('amzState');
                }}
                onChange={handleChangeAmzState}
                error={amzStateError}
                options={locationOptions}
              />
              <SelectField
                fieldClass={narrowSelectFieldClass}
                fieldName="amzSiteCode"
                labelText="Site Code*"
                onBlur={() => {
                  // handleValidate('amzSiteCode');
                }}
                onChange={handleChangeAmzSiteCode}
                error={amzSiteCodeError}
                options={siteCodeOptions}
              />
              <div className={formFieldClass}>
                <div className={styles['field-wrapper']}>
                  <label htmlFor="phoneConsent" className={styles['check-label']}>
                    <input
                      type="checkbox"
                      name="phone_consent"
                      id="field[15]"
                      checked={phoneConsent}
                      onChange={handlePhoneConsent}
                      defaultValue="true"
                    />
                    I provide my consent for Pathstream or its partners to contact me using calls or text messaging at
                    the phone number provided above.
                  </label>
                </div>
              </div>
            </>
          )}
          {amzCurrentEmployee === 'no' && (
            <>
              <SelectField
                fieldClass={wideSelectFieldClass}
                fieldName="amzCurrentGoals"
                labelText="What do you want to accomplish?"
                onBlur={() => {
                  handleValidate('amzCurrentGoals');
                }}
                onChange={handleChangeAmzCurrentGoals}
                error={amzCurrentGoalsError}
                options={amzCurrentGoalsOptions}
              />
              <SelectField
                fieldClass={wideSelectFieldClass}
                fieldName="amzCurrentInterests"
                labelText="What skills are you most interested in learning?"
                onBlur={() => {
                  handleValidate('amzCurrentInterests');
                }}
                onChange={handleChangeAmzCurrentInterests}
                error={amzCurrentInterestsError}
                options={amzCurrentInterestsOptions}
              />
            </>
          )}
        </div>
        <br />
        <div className={classnames(styles['button-wrapper'], styles['inline-style'])}>
          {buttonDisabled && !isIncomplete() && 'Thinking...'}
          {!buttonDisabled && (
            <button id="form-164-submit" className={styles.submit} type="submit" disabled={isIncomplete()}>
              {simpleForm && 'Unlock My Career'}
              {!simpleForm && amzCurrentEmployee === 'yes' && 'Check my eligibility'}
              {!simpleForm && amzCurrentEmployee === 'no' && 'Learn more about Pathstream'}
              {!simpleForm && !amzCurrentEmployee && 'Complete the form to continue.'}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};
