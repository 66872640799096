import Airtable from 'airtable';

Airtable.configure({ apiKey: 'pat84tuLhN2nkfDyh.b05df2647dd5cb68ad284705b80a275951d4f6792790d9ab6657afc0f6350cf0' });

export const getEligibleEmployers = async () => {
  const base = Airtable.base('appaBapbl0lQIAaA9');
  const eligibleEmployers = [];
  await base('tblNbsCL5AufNvftA')
    .select({
      // Table name: Eligibility Search
      view: 'viwF8SInEtsNmM3sU',
    })
    .eachPage((records, fetchNextPage) => {
      records.forEach(record => {
        // console.log('Retrieved', record.get('Employer Name'));
        eligibleEmployers.push(record.fields);
      });
      fetchNextPage();
    });
  return eligibleEmployers;
};

export const getEmployerRecord = async employerID => {
  // console.log('getting employer record', employerID);
  const base = Airtable.base('appaBapbl0lQIAaA9');
  const employerResult = [];
  const records = await new Promise((resolve, reject) => {
    base('tblNbsCL5AufNvftA')
      .select({
        // Table name: Eligibility Search
        view: 'viwF8SInEtsNmM3sU',
        filterByFormula: `{employer ID} = "${employerID}"`,
      })
      .firstPage((err, recs) => {
        if (err) {
          console.log(err);
          reject(err);
          return;
        }
        resolve(recs);
      });
  });

  records.forEach(record => {
    // console.log('Retrieved', record.fields);
    employerResult.push(record.fields);
  });
  // console.log('returning', employerResult);
  return employerResult;
};

export const getButtonsToTrack = async pageId => {
  const base = Airtable.base('appaBapbl0lQIAaA9');
  const buttonsToTrack = [];
  // console.log('getting buttons to track ', pageId);
  await base('tblXOxixHNuz2H7of')
    .select({
      // Table name: Button Events Config
      view: 'viwrishuQIV5l8xch',
      filterByFormula: `{page_id} = "${pageId}"`,
    })
    .eachPage((records, fetchNextPage) => {
      records.forEach(record => {
        // console.log('Retrieved', record.get('button_name'));
        // console.log('Retrieved', record.fields);
        buttonsToTrack.push(record.fields);
      });
      fetchNextPage();
    });
  return buttonsToTrack;
};
