.requestDemoForm {
  width: 400px;
  display: flex;
  flex-direction: column;
  color: #ebf8ff;
  margin: 30px 40px;

  .customSelect {
    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }

    width: 100%;
    cursor: pointer;
    display: grid;
    grid-template-areas: 'select';
    align-items: center;
    position: relative;
    margin-bottom: 25px;

    &::after {
      content: '';
      width: 0.8em;
      height: 0.5em;
      background-color: #ebf8ff;
      clip-path: polygon(100% 0%, 0 0%, 50% 100%);
      grid-area: select;
      justify-self: end;
    }

    select {
      // A reset of styles, including removing the default dropdown arrow
      appearance: none;

      // Additional resets for further consistency
      background-color: transparent;
      border: none;
      padding: 0 1em 0 0;
      margin: 0;
      width: 100%;
      font-family: inherit;
      font-size: inherit;
      cursor: inherit;
      line-height: inherit;
      outline: none;
      color: #ebf8ff;
      border-bottom: solid 1px #ebf8ff;
      grid-area: select;

      &:focus + .focus {
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        border-radius: inherit;
      }
    }
  }

  .label {
    font-size: 10px;
    color: #8e8e8e;
    margin-bottom: 5px;
    height: 20px;
  }

  .error {
    height: 30px;
    margin-top: -25px;
    font-size: 10px;
    margin-left: 5px;
    color: #ffb9b9;
  }

  input {
    color: #ebf8ff;
    width: 100%;
    margin-bottom: 25px;
    background: none;
    border: none;
    border-bottom: solid 1px #ebf8ff;
    padding-bottom: 8px;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-weight: bold;
      color: #fff;
    }

    &:focus {
      border-bottom: solid 1px #4fb9ef;
    }
  }

  .button {
    display: inline-block;
    margin-top: 20px;
    text-align: center;
    padding: 12px 30px;
    font-weight: 900;
    color: #039be8;
    background: #fff;
    border-radius: 5px;
    font-size: 14px;
    width: 180px;

    &:hover {
      cursor: pointer;
      background: #039be8;
      color: #fff;
    }
  }

  .success {
    margin-top: 60px;

    .title {
      margin-bottom: 10px;
      font-size: 36px;
      line-height: 1.3em;
      font-weight: 900;
    }

    .details {
      font-size: 14px;
    }
  }
}
