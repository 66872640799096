import { render } from 'react-dom';
import { addError } from './datadog';

export const replaceWithReactComponent = (ReactComponent, selector) => {
  try {
    const element = document.querySelectorAll(selector)[0];
    // console.log(element);
    if (element) {
      render(<ReactComponent />, element);
    }
  } catch (error) {
    console.warn(`Failure to replace selector ${selector}`, { error });
    addError(`Failure to replace selector ${selector}`, { error });
  }
};
