import { useState } from 'react';
import * as EmailValidator from 'email-validator';
import * as styles from './RequestDemoForm.module.scss';
import { submitHubspotForm } from './api/hubspot';
import { getCookie } from './utils/cookies';
import { customEventB2BRequestDemoSubmit, customEventIdentifyB2BUser } from './analytics/customEvents';

export const RequestDemoForm = () => {
  const defaults = {
    name: 'Full name*',
    email: 'Email address*',
    phone: 'Phone number*',
    company: 'Company name*',
  };
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [size, setSize] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [companyError, setCompanyError] = useState('');
  const [sizeError, setSizeError] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const handleChangeName = e => {
    setName(e.target.value);
  };

  const handleChangeEmail = e => {
    setEmail(e.target.value);
  };

  const handleChangePhone = e => {
    setPhone(e.target.value);
  };

  const handleChangeCompany = e => {
    setCompany(e.target.value);
  };

  const handleChangeSize = e => {
    setSize(e.target.value);
  };

  const handleValidate = field => {
    let valid = true;
    if (field === 'name') {
      if (!name) {
        setNameError('Name is required');
        valid = false;
      } else {
        setNameError('');
      }
    }
    if (field === 'email') {
      if (!email) {
        setEmailError(`Email is required`);
        valid = false;
      } else if (!EmailValidator.validate(email)) {
        setEmailError(`This email doesn't look right`);
        valid = false;
      } else {
        setEmailError('');
      }
    }
    if (field === 'phone') {
      if (!phone) {
        setPhoneError('Phone is required');
        valid = false;
      } else {
        setPhoneError('');
      }
    }
    if (field === 'company') {
      if (!company) {
        setCompanyError('Company is required');
        valid = false;
      } else {
        setCompanyError('');
      }
    }
    if (field === 'size') {
      if (!size) {
        setSizeError('Company size is required');
        valid = false;
      } else {
        setSizeError('');
      }
    }
    return valid;
  };

  const handleSubmit = () => {
    if (
      handleValidate('name') &&
      handleValidate('email') &&
      handleValidate('phone') &&
      handleValidate('company') &&
      handleValidate('size')
    ) {
      customEventIdentifyB2BUser(email, {
        email,
        full_name: name,
        phone,
        company,
        company_size: size,
      });

      customEventB2BRequestDemoSubmit({
        name,
        email,
        phone,
        company,
        size,
      });

      const hubspotTrackingCookie = getCookie('hubspotutk') ? getCookie('hubspotutk') : '';

      const hubspotFormData = {
        context: {
          hutk: hubspotTrackingCookie,
        },
        fields: [
          {
            objectTypeId: '0-1',
            name: 'email',
            value: email,
          },
          {
            objectTypeId: '0-1',
            name: 'full_name',
            value: name,
          },
          {
            objectTypeId: '0-1',
            name: 'phone',
            value: phone,
          },
          {
            objectTypeId: '0-1',
            name: 'company',
            value: company,
          },
          {
            objectTypeId: '0-1',
            name: 'company_size',
            value: size,
          },
        ],
      };
      submitHubspotForm('a2d87bcd-afd9-474c-8ec0-2a95d67d59fe', hubspotFormData);
      setShowSuccess(true);
    }
  };

  return (
    <div className={styles.requestDemoForm}>
      {showSuccess && (
        <div className={styles.success}>
          <div className={styles.title}>We look forward to chatting!</div>
          <div className={styles.details}>A member of our team will reach out within a business day.</div>
        </div>
      )}
      {!showSuccess && (
        <>
          <div className={styles.label}>{name && `Name`}</div>
          <input
            placeholder={defaults.name}
            onBlur={() => {
              handleValidate('name');
            }}
            onChange={handleChangeName}
            name="name"
            value={name}
          />
          <div className={styles.error}>{nameError}</div>
          <div className={styles.label}>{email && `Email`}</div>
          <input
            type="email"
            onBlur={() => {
              handleValidate('email');
            }}
            placeholder={defaults.email}
            onChange={handleChangeEmail}
            name="email"
            value={email}
          />
          <div className={styles.error}>{emailError}</div>
          <div className={styles.label}>{phone && `Phone number`}</div>
          <input
            placeholder={defaults.phone}
            onBlur={() => {
              handleValidate('phone');
            }}
            onChange={handleChangePhone}
            name="phone"
            value={phone}
          />
          <div className={styles.error}>{phoneError}</div>
          <div className={styles.label}>{company && `Company`}</div>
          <input
            placeholder={defaults.company}
            onBlur={() => {
              handleValidate('company');
            }}
            onChange={handleChangeCompany}
            name="company"
            value={company}
          />
          <div className={styles.error}>{companyError}</div>
          <div className={styles.label}>Company Size</div>
          <div className={styles.customSelect}>
            <select value={size} required onChange={handleChangeSize}>
              <option value="" disabled selected hidden>
                Please Choose...
              </option>
              <option value="0-100">0 - 100</option>
              <option value="100-500">100 - 500</option>
              <option value="500-1000">500 - 1000</option>
              <option value="1000-5000">1000 - 2000</option>
              <option value="5000-10,000">5000 - 10,000</option>
              <option value="10,000+">10,000+</option>
            </select>
            <span className={styles.focus} />
          </div>
          <div className={styles.error}>{sizeError}</div>
          <a onClick={handleSubmit} className={styles.button}>
            Request a demo
          </a>
        </>
      )}
    </div>
  );
};
