.ineligible-text {
  color: #fff !important;
  text-align: center;
}

.apply1Form {
  font-family: 'Manrope';
  font-size: 14px;
  line-height: 1.6;
  position: relative;
  text-align: left;
  margin: 25px auto 0;
  padding: 50px auto 0;
  box-sizing: border-box;
  zoom: 1;
  background: transparent;
  border: 0 solid #b0b0b0;
  border-radius: 5px;
  color: #000;
  * {
    outline: 0;
  }

  h1 {
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 24px;
  }

  .quiz-instructions {
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 24px;
  }

  input {
    border: solid 1px #9d9d9d;
    border-radius: 5px;
    padding: 10px 18px;
  }

  input[type='text'].has-error {
    border: #f37c7b 1px solid;
  }

  input[type='tel'].has-error {
    border: #f37c7b 1px solid;
  }

  input[type='checkbox'].has-error {
    outline: #f37c7b 1px solid;
  }

  input {
    width: 300px;
    padding: 7px;
    height: auto;
    border: #979797 1px solid;
    border-radius: 4px;
    color: #000;
    font-size: 14px;
    box-sizing: border-box;
  }

  textarea {
    width: 610px;
    padding: 7px;
    height: auto;
    border: #979797 1px solid;
    border-radius: 4px;
    color: #000;
    font-size: 14px;
    box-sizing: border-box;
    font-family: inherit;
  }

  input[type='checkbox'] {
    width: auto;
    padding: 6px;
  }

  select {
    width: 100%;
    padding: 6px;
    height: auto;
    border: #979797 1px solid;
    border-radius: 4px;
    box-sizing: border-box;
    font-family: inherit;
  }

  .submit {
    margin: auto;
    padding: 10px 15px;
    background: #039be8;
    border: solid 2px #039be8;
    width: 174px;
    text-align: center;
    transition: 0.5s all ease-out;
    color: #ffffff;
    font-weight: bold;
    font-family: 'Manrope', arial, sans-serif;
    font-size: 16px;
    border-radius: 8px;

    &:hover {
      background: #047fbd;
      color: #fff;
      cursor: pointer;
    }

    // Button is gray when disabled
    &:disabled {
      background: #b0b0b0;
      border: solid 1px #b0b0b0;
      color: #ffffff;
      cursor: not-allowed;
    }
  }

  .next {
    margin: auto;
    padding: 10px 15px;
    background: #ffffff;
    border: solid 2px #039be8;
    width: 174px;
    text-align: center;
    transition: 0.5s all ease-out;
    color: #039be8;
    font-weight: bold;
    font-family: 'Manrope', arial, sans-serif;
    font-size: 16px;
    border-radius: 8px;
  }

  .light-style {
    color: #fff;
  }

  .form-label {
    font-weight: bold;
    margin-bottom: 8px;
    display: block;
  }

  .check-label {
    font-weight: bold;
    overflow: visible;
    display: block;

    .check-box-text {
      padding-left: 24px;
    }
  }

  .form-element {
    position: relative;
    margin-bottom: 32px;
    font-size: 0;
    max-width: 100%;
    margin-right: 10px;

    .ineligible-style {
      color: #fff;
    }

    .form-label {
      font-weight: bold;
      margin-bottom: 8px;
      display: block;
    }

    .check-label {
      font-weight: bold;
      overflow: visible;
      display: block;
      margin-bottom: 5px;
    }

    * {
      font-size: 14px;
    }

    input {
      display: block;
      width: 300px;
      box-sizing: border-box;
      font-family: inherit;
    }

    input[type='checkbox'] {
      float: left;
    }
  }

  .form-element.clear {
    clear: both;
    width: 100%;
    float: none;

    &::after {
      clear: left;
    }
  }

  .field-wrapper {
    position: relative;
  }

  .inline-style {
    input {
      width: 300px;
    }
    input[type='checkbox'] {
      width: auto;
    }
    select {
      width: 300px;
    }
    .next {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .wide-select {
    select {
      width: 300px;
    }
  }
  .narrow-select {
    select {
      width: 100px;
    }
  }
  .extra-wide-select {
    select {
      width: 608px;
    }
  }

  .button-wrapper {
    .error-inner.form-error {
      position: static;
    }
    display: block;
    justify-content: center;
    align-items: center;
  }

  &::before {
    content: ' ';
    display: table;
  }

  &::after {
    content: ' ';
    display: table;
    clear: both;
  }

  .form-thank-you {
    position: relative;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 18px;
  }

  .form-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
  }

  .form-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    max-width: 610px;
  }

  label {
    font-size: 16px;
    font-family: 'Manrope', arial, sans-serif;
    line-height: 1.5em;
    margin-bottom: 8px;
    max-width: 610px;
  }

  .mcQuestion {
    font-size: 16px;
    font-family: 'Manrope', arial, sans-serif;
    line-height: 1.5em;
    margin-bottom: 24px;
    max-width: 610px;
    padding-left: 16px;
    text-indent: -16px;
    p {
      margin-bottom: 8px;
    }

    input[type='radio'] {
      width: auto;
      padding: 8px;
      margin-left: 16px;
      margin-bottom: 16px;
    }
    label {
      margin-left: 8px;
    }
  }

  .form-error {
    color: #f37c7b;
    font-size: 16px;
    font-weight: 800;
    font-family: 'Manrope', arial, sans-serif;
    line-height: 1.5em;
    margin-bottom: 8px;
    max-width: 610px;
  }
}

@media all and (min-width: 320px) and (max-width: 667px) {
  ::-webkit-scrollbar {
    display: none;
  }

  .apply1Form {
    margin: 0;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    * {
      box-sizing: border-box;
      font-size: 1em;
    }

    .form-content {
      margin: 0;
    }

    .inline-style {
      float: none;
      display: block;
    }

    .form-element {
      padding: 0;
    }

    .extra-wide-select {
      select {
        max-width: 300px;
      }
    }

    input {
      float: none;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 16px;
      line-height: 1.3em;
    }

    select {
      float: none;
      display: block;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 16px;
      line-height: 1.3em;
      appearance: none;
    }

    textarea {
      float: none;
      display: block;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 16px;
      line-height: 1.3em;
      appearance: none;
    }

    label {
      float: none;
      margin-bottom: 10px;
    }

    p {
      float: none;
      display: block;
      width: 100%;
      margin-bottom: 0.7em;
    }
  }
} ;
