import { useEffect, useState } from 'react';
import { formatDate } from './utils/dates';
import * as styles from './WelcomeToPathstream.module.scss';
import { getSyllabusUrl } from './utils/welcomeToPathstreamSyllabusConfig';
import { getCohorts } from './api/cohorts';

export const WelcomeToPathstream = () => {
  const notFoundUrl = 'https://www.pathstream.com/blog/program-code-error/';
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [courseInfo, setCourseInfo] = useState({});

  let sku = urlParams.get('sku');
  // make exception for walmart skus so they grab info from gld skus
  if (sku.includes('-wal-')) {
    sku = sku.replace('-wal-', '-gld-');
  }

  const welcomeName = urlParams.get('welcomeName');

  useEffect(() => {
    const redirectToErrorPage = errorMessage => {
      console.error(errorMessage);
      window.location.href = notFoundUrl;
    };
    getCohorts(sku, setCourseInfo, redirectToErrorPage);
  }, [sku]);

  return (
    <div className={styles.welcomeToPathstream}>
      <div className={styles.title}>
        <div className={styles.main}>{welcomeName ? `Welcome, ${welcomeName}!` : `Welcome!`}</div>
        <div className={styles.subtitle}>We&apos;re excited to have you start learning with us.</div>
      </div>
      <div className={styles.info}>
        <div className={styles.program}>
          <div className={styles.yourProgram}>Your program</div>
          <div className={styles.programName}>{courseInfo.product_name}</div>
          <div className={styles.partnerName}>{courseInfo.partner_name}</div>
          <div className={styles.courseDates}>{`${formatDate(courseInfo.start_date)} - ${formatDate(
            courseInfo.end_date,
          )}`}</div>
          {!sku.includes('-amz-') && (
            <a href={getSyllabusUrl(sku)} target="_blank" rel="noreferrer">
              <div className={styles.syllabusButton}>Download Syllabus</div>
            </a>
          )}
          {courseInfo.instructor_name && <div>{`Your instructor is ${courseInfo.instructor_name}`}</div>}
        </div>
        <div className={styles.courses}>
          <div className={styles.coursesIntro}>Courses</div>
          {courseInfo.course_details?.map(course => (
            <div className={styles.courseContainer}>
              <div className={styles.courseDates}>{`${formatDate(course.start_date)} - ${formatDate(
                course.end_date,
              )}`}</div>
              <div className={styles.courseTitle}>
                <span className={styles.courseNumber}>{course.order}:</span> {course.name}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
