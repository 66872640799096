export const getCohorts = (sku, success, error) => {
  // Create the new request
  const xhr = new XMLHttpRequest();
  const url = `https://app.pathstream.com/inventory/cohorts/${sku}`;

  xhr.open('GET', url);
  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4 && xhr.status === 200) {
      return success(JSON.parse(xhr.responseText)); // Returns a 200 response if the submission is successful.
    }
    if (xhr.readyState === 4 && xhr.status === 400) {
      return error(xhr.responseText); // Returns a 400 error the submission is rejected.
    }
    if (xhr.readyState === 4 && xhr.status === 403) {
      return error(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
    }
    if (xhr.readyState === 4 && xhr.status === 404) {
      return error(xhr.responseText); // Returns a 404 error if the formGuid isn't found
    }
    return 'Done';
  };

  xhr.send('');
};
