/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
// import * as EmailValidator from 'email-validator';
import * as styles from '../Apply1Form.module.scss';
import { ErrorMessage } from '../ErrorMessage';

const SelectField = props => (
  <div className={props.fieldClass}>
    <label htmlFor={props.fieldName} className={styles['form-label']}>
      {props.labelText}
    </label>
    <div className={styles['field-wrapper']}>
      <select
        id={props.fieldName}
        onBlur={props.onBlur}
        onChange={props.onChange}
        name={props.fieldName}
        value={props.value}
        required>
        <option />
        {props.options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {props.error && ErrorMessage(props.error)}
    </div>
  </div>
);

SelectField.propTypes = {
  fieldClass: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string.isRequired, label: PropTypes.string.isRequired }),
  ).isRequired,
  error: PropTypes.string.isRequired,
  value: PropTypes.string,
};

SelectField.defaultProps = {
  value: '',
};
export default SelectField;
