export const getSyllabusUrl = sku => {
  const skuFilter = subSku => {
    if (sku.includes(subSku)) {
      return sku;
    }
    return '';
  };

  let welcomeSyllabusUrl;

  switch (sku) {
    case skuFilter('ps003f-'):
      if (sku.includes('-emr-')) {
        welcomeSyllabusUrl = 'https://drive.google.com/file/d/1An_9imSoGNiQ6qhi2EvDbHiM47NDIoct/view?usp=sharing';
      } else if (sku.includes('-wfe-')) {
        welcomeSyllabusUrl = 'https://drive.google.com/file/d/11zcYDSZtfkEkp2tGogtYSTb3-YdpophZ/view?usp=sharing';
      } else {
        welcomeSyllabusUrl = 'https://drive.google.com/file/d/1HF1B-MJ1WmGlMMZ-WHFlWmGsuAeAOpYM/view?usp=sharing';
      }
      break;
    case skuFilter('ps004f-'):
      if (sku.includes('-wfe-')) {
        welcomeSyllabusUrl = 'https://drive.google.com/file/d/1-tf7Iy3JmaqHx9pIvxKMKkUU9sb0WDvw/view?usp=sharing';
      } else {
        welcomeSyllabusUrl = 'https://drive.google.com/file/d/19ez0rXOesqhxP6JeZNhKxbSUqD4wBQTm/view?usp=sharing';
      }
      break;
    case skuFilter('ps002f-'):
      welcomeSyllabusUrl = 'https://drive.google.com/file/d/1HRiQmxQzXaLTzZtUYcXc0sJfr-REu_8A/view?usp=sharing';
      break;
    case skuFilter('ps001f-'):
      if (sku.includes('-wfe-')) {
        welcomeSyllabusUrl = 'https://drive.google.com/file/d/1-dnrECiuuVt9BKjLoAkNnqLgqyQWNcAa/view?usp=sharing';
      } else {
        welcomeSyllabusUrl = 'https://drive.google.com/file/d/15eHJCLpQwwMN7Dhvxo6YS0cHRKdg2H7A/view?usp=sharing';
      }
      break;
    case skuFilter('ps004fp-'):
      if (sku.includes('-emr-')) {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/emory-the-data-analytics-professional-certificate/';
      } else if (sku.includes('-tam-')) {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/tamu-the-data-analytics-professional-certificate/';
      } else if (sku.includes('-fam-')) {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/famu-the-data-analytics-professional-certificate/';
      } else {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/data-analytics-professional';
      }
      break;
    case skuFilter('ps003fp-'):
      if (sku.includes('-nyu-')) {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/nyu-the-digital-marketing-certificate/';
      } else if (sku.includes('-emr-')) {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/emory-the-digital-marketing-certificate/';
      } else if (sku.includes('-tam-')) {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/tamu-the-digital-marketing-certificate/';
      } else if (sku.includes('-fam-')) {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/famu-the-digital-marketing-certificate/';
      } else {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/digital-marketing';
      }
      break;
    case skuFilter('ps002all-'): // HAVE TO FIX THIS FOR WALMART VS NOT WALMART
      if (sku.includes('-wal')) {
        welcomeSyllabusUrl =
          'https://www.pathstream.com/guild/the-project-management-certificate-with-agile-certificate_walmart/';
      } else if (sku.includes('-nyu-')) {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/nyu-the-project-management-certificate/';
      } else if (sku.includes('-emr-')) {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/emory-the-project-management-certificate/';
      } else if (sku.includes('-tam-')) {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/tamu-the-project-management-certificate/';
      } else if (sku.includes('-fam-')) {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/famu-the-project-management-certificate/';
      } else {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/the-project-management-certificate/';
      }
      break;
    case skuFilter('ps001all-'):
      if (sku.includes('-emr-')) {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/emory-the-salesforce-business-analyst-certificate/';
      } else if (sku.includes('-fam-')) {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/famu-the-salesforce-business-analyst-certificate/';
      } else {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/salesforce-business-analyst';
      }
      break;
    case skuFilter('ps001fx-'):
      if (sku.includes('-nyu-')) {
        welcomeSyllabusUrl =
          'https://www.pathstream.com/guild/nyu-the-sales-operations-manager-certificate-with-salesforce/';
      } else if (sku.includes('-emr-')) {
        welcomeSyllabusUrl =
          'https://www.pathstream.com/guild/emory-the-sales-operations-manager-certificate-with-salesforce/';
      } else if (sku.includes('-tam-')) {
        welcomeSyllabusUrl =
          'https://www.pathstream.com/guild/tamu-the-sales-operations-manager-certificate-with-salesforce/';
      } else if (sku.includes('-fam-')) {
        welcomeSyllabusUrl =
          'https://www.pathstream.com/guild/famu-the-sales-operations-manager-certificate-with-salesforce/';
      } else {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/sales-operations-manager';
      }
      break;
    case skuFilter('ps004fx-'):
      if (sku.includes('-emr-')) {
        welcomeSyllabusUrl =
          'https://www.pathstream.com/guild/emory-the-business-analytics-and-operations-certificate/';
      } else if (sku.includes('-fam-')) {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/famu-the-business-analytics-and-operations-certificate/';
      } else if (sku.includes('-tam-')) {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/tamu-the-business-analytics-and-operations-certificate/';
      } else {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/the-business-analytics-and-operations-certificate/';
      }
      break;
    case skuFilter('ps004fy-'):
      if (sku.includes('-nyu-')) {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/nyu-data-analytics-and-agile-leadership-certificate/';
      } else if (sku.includes('-emr-')) {
        welcomeSyllabusUrl =
          'https://www.pathstream.com/guild/emory-the-data-analytics-and-agile-leadership-certificate/';
      } else if (sku.includes('-tam-')) {
        welcomeSyllabusUrl =
          'https://www.pathstream.com/guild/tamu-the-data-analytics-and-agile-leadership-certificate/';
      } else if (sku.includes('-fam-')) {
        welcomeSyllabusUrl =
          'https://www.pathstream.com/guild/famu-the-data-analytics-and-agile-leadership-certificate/';
      } else {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/data-analytics-and-agile-leadership';
      }
      break;
    case skuFilter('ps006f-'):
      welcomeSyllabusUrl = 'https://www.pathstream.com/guild/google-it-support';
      break;
    case skuFilter('ps004fa-'):
      welcomeSyllabusUrl = 'https://www.pathstream.com/amazon/syllabus-data/';
      break;
    case skuFilter('ps001fa-'):
      welcomeSyllabusUrl = 'https://drive.google.com/file/d/1uelVh-hg7ruJtXFc4aCE71JC3EAXOek0/view?usp=sharing';
      break;
    case skuFilter('ps007s01-'):
      welcomeSyllabusUrl = 'https://www.pathstream.com/pages/workforce_nyu_leadershipcourse';
      break;
    case skuFilter('ps006x-'):
      welcomeSyllabusUrl = 'https://www.pathstream.com/guild/google_digital_marketing';
      break;
    case skuFilter('ps004fh-'):
      if (sku.includes('-emr-')) {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/emory-the-healthcare-analytics-certificate/';
      } else if (sku.includes('-tam-')) {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/tamu-the-healthcare-analytics-certificate/';
      } else if (sku.includes('-fam-')) {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/famu-the-healthcare-analytics-certificate/';
      } else {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/the-healthcare-analytics-certificate/';
      }
      break;
    case skuFilter('ps007f-'):
      if (sku.includes('-tam-')) {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/tamu-the-frontline-manager-leadership-certificate/';
      } else if (sku.includes('-fam-')) {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/famu-the-frontline-manager-leadership-certificate/';
      } else {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/the-frontline-manager-leadership-program/';
      }
      break;
    case skuFilter('ps007fx-'):
      if (sku.includes('-tam-')) {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/tamu-the-retail-management-and-analytics-certificate/';
      } else if (sku.includes('-fam-')) {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/famu-the-retail-management-and-analytics-certificate/';
      } else if (sku.includes('-hvd-')) {
        welcomeSyllabusUrl =
          'https://www.pathstream.com/guild/harvard-the-retail-management-and-analytics-certificate/';
      } else {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/the-retail-management-and-analytics-certificate/';
      }
      break;
    case skuFilter('ps004fz-'):
      if (sku.includes('-fam-')) {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/famu-advanced-data-analytics-with-python/';
      } else {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/advanced-data-analytics-with-python/';
      }
      break;
    case skuFilter('ps001fy-'):
      if (sku.includes('-fam-')) {
        welcomeSyllabusUrl =
          'https://www.pathstream.com/guild/famu-the-sales-excellence-with-data-analytics-certificate/';
      } else if (sku.includes('-nyu-')) {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/nyu-the-sales-excellence-with-data-certificate/';
      } else {
        welcomeSyllabusUrl = 'https://www.pathstream.com/guild/the-sales-excellence-with-data-certificate/';
      }
      break;
    case skuFilter('ps007fy-'):
      if (sku.includes('-emr-')) {
        welcomeSyllabusUrl =
          'https://www.pathstream.com/guild/emory-the-data-driven-customer-success-professional-certificate/';
      } else if (sku.includes('-tam-')) {
        welcomeSyllabusUrl =
          'https://www.pathstream.com/guild/tamu-the-data-driven-customer-success-professional-certificate/';
      } else if (sku.includes('-fam-')) {
        welcomeSyllabusUrl =
          'https://www.pathstream.com/guild/famu-the-data-driven-customer-success-professional-certificate/';
      } else if (sku.includes('-nyu-')) {
        welcomeSyllabusUrl =
          'https://www.pathstream.com/guild/nyu-the-data-driven-customer-success-professional-certificate/';
      } else {
        welcomeSyllabusUrl =
          'https://www.pathstream.com/guild/the-data-driven-customer-success-professional-certificate/';
      }
      break;
    default:
  }

  if (welcomeSyllabusUrl) {
    return welcomeSyllabusUrl;
  }
  return '';
};
