import { addError } from '/src/utils/datadog';

const retrieveCookieValueByName = name => document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop();
const CART_COOKIE_NAME = 'CartId';

export const loadCart = async () => {
  const cartId = retrieveCookieValueByName(CART_COOKIE_NAME);
  if (!cartId) return null;

  try {
    const response = await fetch(`https://app.pathstream.com/carts?id=${cartId}`);
    if (!response.ok) throw new Error(`Response was ${response.status}`);

    return await response.json();
  } catch (error) {
    addError('Failure retrieving cart', { error });
    return null;
  }
};
