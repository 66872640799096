import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import * as EmailValidator from 'email-validator';

import * as styles from './Apply1Form.module.scss';
import TextField from './formComponents/TextField';
import SelectField from './formComponents/SelectField';
import validPASiteCodes from './validPASiteCodes.json';
import { sendContact } from '../api/registration';
import stateAndSiteCodes from './StateAndSiteCodeValues.json';
import { customEventIdentifyUser, customEventPartialFormSubmit } from '../analytics/customEvents';

const defaults = {
  firstName: 'Type your first name',
  lastName: 'Type your last name',
  email: 'Type your email',
  phone: 'e.g. (555) 555-5555',
};

const stateNames = Object.getOwnPropertyNames(stateAndSiteCodes);
const locationOptions = stateNames.map(state => ({ value: state, label: stateAndSiteCodes[state].state_name }));

const siteCodeListUnique = [...new Set(validPASiteCodes.siteCodes)];
const siteCodeOptions = [
  { value: 'not-listed', label: 'My site code is not listed here' },
  { value: 'dont-know', label: "I don't know my site code" },
  ...siteCodeListUnique.map(code => ({ value: code, label: code })),
];

export const CohortWaitingList = () => {
  const formId = '#cohort-waiting-list';
  const redirect = useRef();

  const [thankYouRedirect, setThankYouRedirect] = useState('');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    amzState: '',
    amzSiteCode: '',
    amzSiteCodeFreeText: '',
    employmentStatus: '',
  });

  const [errors, setErrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [formError, setFormError] = useState(false);

  useEffect(() => {
    const formOptions = document.querySelector(formId);

    if (!formOptions.dataset.thankyouredirect) {
      alert('Error: Form must have data-cohortId, data-programCode, and data-thankYouRedirect attributes.');
    } else {
      setThankYouRedirect(formOptions.dataset.thankyouredirect);
      redirect.current = formOptions.dataset.thankyouredirect;
    }
  }, []);

  const handleInputChange = field => e => {
    setFormData(prevData => ({
      ...prevData,
      [field]: e.target.value,
    }));

    setErrors(prevErrors => ({
      ...prevErrors,
      [field]: '',
    }));
  };

  const handlePhoneChange = e => {
    const phoneNum = e.target.value.replace(/\D/g, '');
    const formattedPhone = phoneNum.length > 10 ? phoneNum.slice(0, 10) : phoneNum;
    // this masks the phone input to (xxx) xxx-xxxx
    const val = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    const phoneFormat = !val[2] ? val[1] : `(${val[1]}) ${val[2]}${val[3] ? `-${val[3]}` : ''}`;

    setFormData(prevData => ({ ...prevData, phone: formattedPhone, phoneMasked: phoneFormat }));
  };

  const handleValidate = field => {
    let error = '';
    switch (field) {
      case 'email':
        if (!formData.email) {
          error = 'Email is required';
        } else if (!EmailValidator.validate(formData.email)) {
          error = "This email doesn't look right";
        }
        break;
      case 'firstName':
        error = !formData.firstName ? 'First name is required' : '';
        break;
      case 'lastName':
        error = !formData.lastName ? 'Last name is required' : '';
        break;
      case 'phone':
        if (!formData.phone) {
          error = 'Phone is required';
        } else if (formData.phone.length < 10) {
          error = 'Phone must be at least 10 digits';
        }
        break;
      case 'amzState':
        error = !formData.amzState ? 'State is required' : '';
        break;
      case 'amzSiteCode':
        error = !formData.amzSiteCode ? 'Site code is required' : '';
        break;
      case 'employmentStatus':
        error = !formData.employmentStatus ? 'This is required' : '';
        break;
      default:
        break;
    }
    setErrors(prevErrors => ({
      ...prevErrors,
      [field]: error,
    }));
    return !error;
  };

  const validateAll = () =>
    ['firstName', 'lastName', 'email', 'phone', 'amzState', 'amzSiteCode', 'employmentStatus'].every(handleValidate);

  const handleSubmit = e => {
    e.preventDefault();

    if (validateAll()) {
      setFormError(false);
      setButtonDisabled(true);

      const dataToSubmit = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        phone: formData.phone,
        email: formData.email.trim().toLowerCase(),
        entry: 'lead_form',
        external_partner_data: {
          form_url: window.location.href,
          amazon_state: formData.amzState,
          employment_status: formData.employmentStatus,
          amazon_site_code: formData.amzSiteCode === 'not-listed' ? formData.amzSiteCodeFreeText : formData.amzSiteCode,
        },
      };

      customEventIdentifyUser(formData.email, formData.firstName, formData.lastName, formData.phone);
      customEventPartialFormSubmit({ ...dataToSubmit, form: formId });

      sendContact(dataToSubmit, status => {
        if (status.includes('contact_id')) {
          window.location.href = thankYouRedirect + document.location.search;
        }
      });
    } else {
      setFormError(true);
      console.log('Form is not valid');
    }
  };

  return (
    <div className={styles.apply1Form}>
      <form onSubmit={handleSubmit}>
        <div className={styles['form-content']}>
          <TextField
            fieldClass={classnames(styles['form-element'], styles['inline-style'])}
            fieldName="firstName"
            labelText="First name*"
            onBlur={() => handleValidate('firstName')}
            onChange={handleInputChange('firstName')}
            textValue={formData.firstName}
            error={errors.firstName}
            placeholder={defaults.firstName}
          />
          <TextField
            fieldClass={classnames(styles['form-element'], styles['inline-style'])}
            fieldName="lastName"
            labelText="Last name*"
            onBlur={() => handleValidate('lastName')}
            onChange={handleInputChange('lastName')}
            textValue={formData.lastName}
            error={errors.lastName}
            placeholder={defaults.lastName}
          />
        </div>
        <div className={styles['form-content']}>
          <TextField
            fieldClass={classnames(styles['form-element'], styles['inline-style'])}
            fieldName="email"
            type="email"
            labelText="Email*"
            onBlur={() => handleValidate('email')}
            onChange={handleInputChange('email')}
            textValue={formData.email}
            error={errors.email}
            placeholder={defaults.email}
          />
          <TextField
            fieldClass={classnames(styles['form-element'], styles['inline-style'])}
            fieldName="phone"
            type="tel"
            labelText="Phone*"
            onBlur={() => handleValidate('phone')}
            onChange={handlePhoneChange}
            textValue={formData.phoneMasked}
            error={errors.phone}
            placeholder={defaults.phone}
          />
        </div>
        <div className={styles['form-content']}>
          <SelectField
            fieldClass={classnames(styles['form-element'], styles['wide-select'])}
            fieldName="amzState"
            labelText="What state do you work in?*"
            onBlur={() => handleValidate('amzState')}
            onChange={handleInputChange('amzState')}
            error={errors.amzState}
            options={locationOptions}
            value={formData.amzState}
          />
          <SelectField
            fieldClass={classnames(styles['form-element'], styles['wide-select'])}
            fieldName="amzSiteCode"
            labelText="What is your Amazon site code?*"
            onBlur={() => handleValidate('amzSiteCode')}
            onChange={handleInputChange('amzSiteCode')}
            error={errors.amzSiteCode}
            options={siteCodeOptions}
            value={formData.amzSiteCode}
          />
        </div>
        {formData.amzSiteCode === 'not-listed' && (
          <div className={styles['form-content']}>
            <TextField
              fieldClass={classnames(styles['form-element'], styles['inline-style'])}
              fieldName="amzSiteCodeFreeText"
              labelText="If your site code is not listed, what is your site code?"
              onChange={handleInputChange('amzSiteCodeFreeText')}
              textValue={formData.amzSiteCodeFreeText}
            />
          </div>
        )}
        <div className={styles['form-content']}>
          <SelectField
            fieldClass={classnames(styles['form-element'], styles['wide-select'])}
            fieldName="employmentStatus"
            labelText="Are you a full time employee?*"
            onBlur={() => handleValidate('employmentStatus')}
            onChange={handleInputChange('employmentStatus')}
            error={errors.employmentStatus}
            options={[
              { label: 'Full time', value: 'full time' },
              { label: 'Part time', value: 'part time' },
            ]}
            value={formData.employmentStatus}
          />
        </div>

        {formError && (
          <div className={styles['form-error']}>
            You are missing a required field. Please check your responses to continue.
          </div>
        )}

        <div className={styles['form-buttons']}>
          <button type="submit" className={styles.submit} disabled={buttonDisabled}>
            {buttonDisabled ? 'Processing application...' : 'Apply'}
          </button>
        </div>
      </form>
    </div>
  );
};
