.ineligible-text {
  color: #fff !important;
  text-align: center;
}

.eligibilityResults {
  margin-top: 20px;
  margin-bottom: 20px;

  .eligibilityResultsInner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .eligibilityResultsLogo {
      align-items: center;
      max-width: 400px;
      margin-bottom: 20px;
    }

    .eligibilityResultsConfirmation {
      h2 {
        line-height: 1.5;
        text-align: center;
        font-weight: bold;
        color: #fff;
      }
    }
  }
}
