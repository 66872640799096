.error {
  padding-bottom: 10px;
  bottom: 48px;
  right: 0;
  display: block;
  position: absolute;
  font-size: 14px;
  z-index: 10000001;

  .error-arrow {
    bottom: 5px;
    right: 15px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #f37c7b;
    position: absolute;
    width: 0;
    height: 0;
  }

  .error-inner {
    padding: 8px 12px;
    background-color: #f37c7b;
    font-size: 14px;
    font-family: arial, sans-serif;
    color: #fff;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
  }
}
