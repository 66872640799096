import { analyticsEnabled } from '../utils/environment';

const doPost = (url, data, callback) => {
  if (!analyticsEnabled) {
    console.log('data: ', data);
    return;
  }
  const xhr = new XMLHttpRequest();
  const finalData = JSON.stringify(data);

  xhr.open('POST', url);
  xhr.setRequestHeader('Content-Type', 'application/json');

  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4 && xhr.status === 200) {
      return callback(xhr.responseText);
    }
    if (xhr.readyState === 4 && xhr.status === 400) {
      return callback(xhr.responseText);
    }
    if (xhr.readyState === 4 && xhr.status === 403) {
      return callback(xhr.responseText);
    }
    if (xhr.readyState === 4 && xhr.status === 404) {
      return callback(xhr.responseText);
    }
    return 'Done';
  };

  xhr.send(finalData);
};

export const submitRegistrationForm = (data, callback) => {
  const url = analyticsEnabled
    ? 'https://app.pathstream.com/registrations' // Production
    : 'https://app.staging.pathstream.com/registrations'; // Staging

  doPost(url, data, callback);
};

export const sendContact = (data, callback) => {
  const url = 'https://app.pathstream.com/contacts';
  doPost(url, data, callback);
};
